import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { ColumnDef } from '@tanstack/react-table';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import { InvoicesResponse } from 'types/invoices';
import formatDate from 'utils/helpers/date';
import { joinName } from 'utils/helpers/name';
import { Spin } from 'antd';
import { capitalizeFirstLetter } from 'utils/helpers/capitalize';

const getInvoices = async (currentPage: number) => {
  const data = await request({
    url: '/invoices',
    method: 'GET',
    params: { page: currentPage }
  });
  return data;
};

const handleInvoiceAutomation = async (id: string) => {
  const data = await request({
    url: `/invoices/automation/${id}`,
    method: 'PUT',
    data: {}
  });
  return data;
};

export default function InvoicesPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [processingId, setProcessingId] = useState('');
  const queryClient = useQueryClient();

  const { data: invoicesData, isLoading } = useQuery({
    queryKey: ['invoices', currentPage],
    queryFn: () => getInvoices(currentPage)
  });

  const { mutateAsync: automateInvoice, isPending: isAutomationPending } =
    useMutation({
      mutationFn: (id: string) => handleInvoiceAutomation(id),
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ['automate_invoice'] });
        queryClient.refetchQueries({ queryKey: ['invoices'] });
      }
    });

  const packages = useMemo(() => {
    return invoicesData?.invoices ?? [];
  }, [invoicesData?.invoices]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold mb-2'>Invoices</h1>
        <div className='overflow-auto h-[calc(100vh_-_220px)]'>
          <DataTable
            isLoading={isLoading}
            data={packages}
            columns={columns(
              automateInvoice,
              isAutomationPending,
              setProcessingId,
              processingId
            )}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={invoicesData?.pagination.total_count}
        totalPage={invoicesData?.pagination.total_pages}
        perPage={invoicesData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

const columns = (
  automateInvoice: (id: string) => void,
  isAutomationPending: boolean,
  setProcessingId: any,
  processingId: string
): ColumnDef<InvoicesResponse>[] => [
  {
    header: 'Created At',
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Name',
    accessorKey: 'first_name',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/clients/${info.row.original.id}`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {joinName(info.getValue<string>(), info.row.original.last_name)}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email'
  },
  {
    header: 'Invoice Id',
    accessorKey: 'pb_invoice_id',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/clients/${info.row.original.client_record_id}/billing/${info.row.original.pb_invoice_id}/view`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {info.getValue<string>()}
        </p>
      </a>
    )
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: (info) => capitalizeFirstLetter(info.getValue() as string)
  },
  {
    header: 'Updated At',
    accessorKey: 'updated_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Action',
    accessorKey: 'email',
    cell: (info) => (
      <button
        disabled={isAutomationPending}
        className={`text-orange border border-1 p-1 border-orange rounded-lg  cursor-pointer whitespace-nowrap font-medium mr-0.5 ml-2 hover:bg-orange hover:text-white ${processingId === info.row.original.id && isAutomationPending && 'bg-orange'} disabled:cursor-not-allowed w-36`}
        onClick={() => {
          setProcessingId(String(info.row.original.id));
          automateInvoice(String(info.row.original.id));
        }}
      >
        {processingId === info.row.original.id && isAutomationPending ? (
          <Spin />
        ) : (
          'Invoice Automation'
        )}
      </button>
    )
  }
];
