import { Tabs, TabsProps } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function Tab({ items, ...props }: TabsProps) {
  const navigate = useNavigate();

  const onChange = (key: string) => {
    navigate(`/${key}`);
  };

  return <Tabs items={items} onChange={onChange} {...props} />;
}
