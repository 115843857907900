import { ArrowRightOutlined, RedoOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Input } from 'antd';
import Button from 'components/button/Button';
import { Label } from 'components/label/Label';
import Radio from 'components/radio/Radio';
import Select from 'components/select/Select';
import Textarea from 'components/textarea/Textarea';
import WysiwygEditor from 'components/wysiwygEditor/WysiwygEditor';
import {
  semaglutideDoseOptions,
  tirzepatideDoseOptions
} from 'constants/doseOptions';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { VirtualFollowupsResponse } from 'types/virtualFollowups';
import { request } from 'utils/api';
import { capitalizeFirstLetter } from 'utils/helpers/capitalize';

interface VirtualFollowupsFormInputs {
  id?: number | string | null;
  satisfaction?: string;
  appetite_suppression?: string;
  adjust_medication_dose?: string;
  medication?: string;
  weight?: string | number;
  dose?: number | string;
  side_effects?: string;
  message_to_patient?: string;
  vf_interval_history?: string;
  vf_weight_loss_history?: string;
  switch_med_dose?: number | string;
  weight_trends?: string;
  name?: string;
  status?: string;
  current_medication?: string;
  current_package?: string;
  current_goals?: string;
  goals?: string;
  preferred_plan?: string;
  adjust_plan?: string;
  current_dose?: string;
  discount?: string;
}

type Item = {
  name: string;
  selected: boolean;
};

const handleUpdateVirtualFollowups = async (
  payload: VirtualFollowupsFormInputs,
  id: string
) => {
  const response = await request({
    url: `form-requests/${id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

const handleRefreshAIPrompts = async (columnName: string, id: string) => {
  const response = await request({
    url: `form-requests/refresh-ai-prompts/${id}`,
    method: 'PUT',
    data: { column_name: columnName }
  });
  return response;
};

const handleCreateVirtualFollowups = async (
  payload: VirtualFollowupsFormInputs
) => {
  const response = await request({
    url: 'form-requests',
    method: 'POST',
    data: payload
  });
  return response;
};

export default function VirtualFollowupsForm({
  info,
  setIsClose
}: {
  info?: VirtualFollowupsResponse;
  setIsClose: any;
}) {
  const { id } = useParams();
  const [refreshedData, setRefreshedData] =
    useState<VirtualFollowupsFormInputs>({});
  const [refreshingColumn, setRefreshingColumn] = useState('');
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields }
  } = useForm<VirtualFollowupsFormInputs>({
    values: refreshedData.id
      ? {
          medication: refreshedData.medication,
          name: refreshedData.name,
          weight: refreshedData.weight,
          dose: refreshedData.dose,
          id: refreshedData.id,
          satisfaction: refreshedData.satisfaction,
          appetite_suppression: refreshedData.appetite_suppression,
          adjust_medication_dose: refreshedData.adjust_medication_dose,
          weight_trends: refreshedData.weight_trends,
          side_effects: refreshedData.side_effects,
          message_to_patient: refreshedData.message_to_patient,
          vf_interval_history: refreshedData.vf_interval_history,
          vf_weight_loss_history: refreshedData.vf_weight_loss_history,
          current_medication: refreshedData.current_medication,
          current_package: refreshedData.current_package,
          current_goals: refreshedData.current_goals,
          switch_med_dose: '',
          goals: '',
          preferred_plan: '',
          adjust_plan: 'No change',
          discount: ''
        }
      : info
        ? {
            medication: info.medication,
            name: info.name,
            weight: info.weight,
            dose: info.dose,
            id: info.id,
            satisfaction: info.satisfaction,
            appetite_suppression: info.appetite_suppression,
            adjust_medication_dose: info.adjust_medication_dose,
            weight_trends: info.weight_trends,
            side_effects: info.side_effects,
            message_to_patient: info.message_to_patient,
            vf_interval_history: info.vf_interval_history,
            vf_weight_loss_history: info.vf_weight_loss_history,
            current_medication: info.current_medication,
            current_package: info.current_package,
            current_goals: info.current_goals,
            switch_med_dose: '',
            goals: '',
            preferred_plan: '',
            adjust_plan: 'No change',
            discount: ''
          }
        : {
            medication: '',
            name: '',
            weight: '',
            dose: '',
            id: null,
            satisfaction: '',
            appetite_suppression: '',
            adjust_medication_dose: '',
            weight_trends: '',
            side_effects: '',
            message_to_patient: '',
            vf_interval_history: '',
            vf_weight_loss_history: '',
            switch_med_dose: '',
            current_medication: '',
            current_package: '',
            current_goals: '',
            goals: '',
            preferred_plan: '',
            adjust_plan: 'No change',
            discount: ''
          }
  });

  const adjustMedicationDose = watch('dose');
  const selectedMedication = watch('medication');
  const adjustPlan = watch('adjust_plan');
  const selectedInterval = watch('preferred_plan');

  const { mutateAsync: updateVirtualFollowups, isPending: isUpdatePending } =
    useMutation({
      mutationFn: (data: VirtualFollowupsFormInputs) =>
        handleUpdateVirtualFollowups(data, String(info?.id)),
      onSuccess(data) {
        if (data !== undefined) {
          reset();
          queryClient.invalidateQueries({ queryKey: ['virtual_followups'] });
          queryClient.refetchQueries({ queryKey: ['virtual_followups'] });
          setIsClose();
        }
      }
    });

  const { mutateAsync: refreshAIPrompts, isPending: isRefreshPending } =
    useMutation({
      mutationFn: (columnName: string) =>
        handleRefreshAIPrompts(columnName, String(info?.id)),
      onSuccess(data) {
        if (data !== undefined) {
          setRefreshedData(data.form_request);
        }
      }
    });

  const { mutateAsync: createVirtualFollowups, isPending: isCreatePending } =
    useMutation({
      mutationFn: (data: VirtualFollowupsFormInputs) =>
        handleCreateVirtualFollowups(data),
      onSuccess(data) {
        if (data !== undefined) {
          reset();
          queryClient.invalidateQueries({ queryKey: ['virtual_followups'] });
          setIsClose();
        }
      }
    });

  const onSubmit = (data: VirtualFollowupsFormInputs) => {
    if (data?.switch_med_dose) {
      data = { ...data, dose: data?.switch_med_dose };
      delete data.switch_med_dose;
    }
    let dirtyData: Partial<VirtualFollowupsFormInputs> = {};

    Object.keys(data).forEach((key) => {
      const objKey = key as keyof VirtualFollowupsFormInputs;

      if (dirtyFields[objKey]) {
        const value = data[objKey];
        if (typeof value === 'string' || value === undefined) {
          dirtyData[objKey] = value;
        } else if (value !== null) {
          dirtyData[objKey] = String(value);
        }
      }
    });
    if (info) {
      dirtyData = { ...dirtyData, discount: 'No Discount', status: 'Reviewed' };
      updateVirtualFollowups(dirtyData);
    } else {
      dirtyData = { ...dirtyData, status: 'Pending' };
      createVirtualFollowups({ ...dirtyData, id: id });
    }
  };

  const isPending = isUpdatePending || isCreatePending;
  type DoseOption = {
    value: string;
    label: string;
  };

  function getSelectedNames(data: Item[]): string | undefined {
    if (data) {
      return data
        .filter((item) => item.selected)
        .map((item) => item.name)
        .join(', ');
    }
  }

  function getDoseOptions(
    medication: string,
    currentDose: number | null
  ): DoseOption[] {
    let doseOptions =
      medication === 'semaglutide'
        ? semaglutideDoseOptions
        : tirzepatideDoseOptions;
    doseOptions = doseOptions.sort((a, b) => Number(a.value) - Number(b.value));

    if (currentDose === null) {
      currentDose = Number(doseOptions[0].value);
    }

    const currentIndex =
      doseOptions.findIndex(
        (option) => Number(option.value) === Number(currentDose)
      ) < 0
        ? 0
        : doseOptions.findIndex(
            (option) => Number(option.value) === Number(currentDose)
          );
    let result: DoseOption[] = [];

    if (currentIndex < doseOptions.length - 1) {
      result.push({
        value: doseOptions[currentIndex + 1].value,
        label: `Increase to ${doseOptions?.[currentIndex + 1]?.value} mg`
      });
    }

    result.push({
      value: doseOptions?.[currentIndex]?.value,
      label: `Continue with ${doseOptions?.[currentIndex]?.value} mg`
    });

    if (currentIndex > 0) {
      result.push({
        value: doseOptions[currentIndex - 1].value,
        label: `Decrease to ${doseOptions?.[currentIndex - 1]?.value} mg`
      });
    }

    return result;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-row'>
        <div className='w-1/2 border-r'>
          <p className='text-base font-semibold border-b mr-9 pb-2'>
            Patient Name - {info?.name}
          </p>
          <div className='m-3 ml-0'>
            <div className='mb-6'>
              <Label className='!font-normal mb-2'>
                <span className='flex flex-row'>
                  <span>Interval History</span>
                  {isRefreshPending &&
                  refreshingColumn === 'vf_interval_history' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('vf_interval_history');
                        setRefreshingColumn('vf_interval_history');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='vf_interval_history'
                control={control}
                render={({ field }) => <WysiwygEditor {...field} />}
              />
            </div>
            <div className='mb-6'>
              <Label className='!font-normal mb-2'>
                <span className='flex flex-row'>
                  <span>Weight Loss History</span>
                  {isRefreshPending &&
                  refreshingColumn === 'vf_weight_loss_history' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('vf_weight_loss_history');
                        setRefreshingColumn('vf_weight_loss_history');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='vf_weight_loss_history'
                control={control}
                render={({ field }) => <WysiwygEditor {...field} />}
              />
            </div>
          </div>
        </div>
        <div className='w-1/2 pl-4'>
          <div className='border-b flex flex-row justify-between'>
            <span className='text-base font-semibold pb-2'>Review</span>
            <ArrowRightOutlined
              onClick={() => setIsClose()}
              className='text-lg pr-4 pb-3'
            />
          </div>
          <div className='mt-3.5 mb-20'>
            <div className='flex flex-row gap-3'>
              <div className='w-[9.8rem]'>
                <Label className='!font-normal'>Current Medication</Label>
                <Controller
                  disabled
                  name='current_medication'
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='text'
                      className=' mt-2 w-full h-[30px]'
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='w-[7rem]'>
                <Label className='!font-normal'>Satisfaction</Label>
                <Controller
                  disabled
                  name='satisfaction'
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='text'
                      className=' mt-2 w-full h-[30px]'
                      {...field}
                    />
                  )}
                />
              </div>
              <div>
                <Label className='!font-normal'>Appetite suppression</Label>
                <Controller
                  disabled
                  name='appetite_suppression'
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='text'
                      className=' mt-2 w-full h-[30px]'
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='w-[10rem]'>
                <Label className='!font-normal'>Weight Trends</Label>
                <Controller
                  name='weight_trends'
                  disabled
                  control={control}
                  render={({ field }) => (
                    <Textarea
                      type='text'
                      row={1}
                      className='mt-2 cursor-not-allowed'
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
            <div className='flex flex-row gap-3 mt-3'>
              <div>
                <Label className='!font-normal w-[23rem]'>
                  Current Package
                </Label>
                <Controller
                  disabled
                  name='current_package'
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='text'
                      className=' mt-2 w-full h-[30px]'
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='w-[6rem]'>
                <Label className='!font-normal'>Current Goals</Label>
                <Controller
                  disabled
                  name='current_goals'
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='text'
                      className=' mt-2 w-full h-[30px]'
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='w-[6rem]'>
                <Label className='!font-normal mb-2'>Current Dose</Label>
                <span className='border border-1 rounded-lg bg-gray-100 text-gray-400 font-light border-gray-300 py-[4px] px-[6px] flex w-[8rem] h-[1.9rem] cursor-not-allowed'>
                  {info?.dose}
                </span>
              </div>
            </div>
            {info?.package && (
              <div>
                <div className='border border-1 rounded p-2 my-3 mx-5 bg-light-orange'>
                  <div className='font-medium border-b m-2 pb-1 text-xs'>
                    Package ({getSelectedNames(info?.package.pb_package_name)})
                  </div>
                  <div className='flex flex-row text-xs'>
                    <div className='flex flex-col w-1/2 border-r mr-3'>
                      <span className='flex flex-row'>
                        <span className='font-medium'>Invoice Amount:</span>
                        <span className='ml-1'>
                          ${info?.package.invoice_amount}
                        </span>
                      </span>
                      <span className='flex flex-row'>
                        <span className='font-medium'>Discount Tag:</span>
                        <span className='ml-1'>
                          {info?.package.discount_tag}
                        </span>
                      </span>
                    </div>
                    <div className='flex flex-col w-1/2'>
                      <span className='flex flex-row'>
                        <span className='font-medium'>
                          Payment Plan Amount:
                        </span>
                        <span className='ml-1'>
                          ${info?.package.payment_plan_amount}
                        </span>
                      </span>
                      <span className='flex flex-row'>
                        <span className='font-medium'>
                          Payment Plan Duration:
                        </span>
                        <span className='ml-1'>
                          {info?.package.payment_plan_duration}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {info?.pb_session && (
              <div>
                <div className='border border-1 rounded p-2 my-3 mx-5 bg-neutral-300'>
                  <div className='font-medium border-b m-2 pb-1 text-xs flex justify-between'>
                    <span>
                      Session Details ({info?.pb_session.session_date})
                    </span>
                    <span>{info.pb_session?.time_zone}</span>
                  </div>
                  <div className='flex flex-row text-xs'>
                    <div className='flex flex-col w-1/2 border-r mr-3'>
                      <span className='flex flex-row'>
                        <span className='font-medium'>Duration:</span>
                        <span className='ml-1'>
                          {info.pb_session?.duration / 60} hrs
                        </span>
                      </span>
                      <span className='flex flex-row'>
                        <span className='font-medium'>Service Type:</span>
                        <span className='ml-1'>
                          {capitalizeFirstLetter(info.pb_session?.service_type)}
                        </span>
                      </span>
                      <span className='flex flex-row'>
                        <span className='font-medium'>Upcoming:</span>
                        <span className='ml-1'>
                          {info.pb_session?.upcoming ? 'Yes' : 'No'}
                        </span>
                      </span>
                    </div>
                    <div className='flex flex-col w-1/2'>
                      <span className='flex flex-row'>
                        <span className='font-medium'>
                          Client Confirmation Status:
                        </span>
                        <span className='ml-1'>
                          {capitalizeFirstLetter(
                            info.pb_session?.client_confirmation_status
                          )}
                        </span>
                      </span>
                      <span className='flex flex-row'>
                        <span className='font-medium'>
                          Confirmation Status:
                        </span>
                        <span className='ml-1'>
                          {capitalizeFirstLetter(
                            info.pb_session.confirmation_status
                          )}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='mt-3 grid grid-cols-3'>
              <div className='mb-4'>
                <Label className='!font-normal'>Adjust Plan</Label>
                <Controller
                  name='adjust_plan'
                  control={control}
                  render={({ field }) => (
                    <Radio
                      className='w-full mt-2 h-[38px]'
                      {...field}
                      options={[
                        {
                          label: 'No change',
                          value: 'No change'
                        },
                        {
                          label: 'Change Plan',
                          value: 'Change Plan'
                        }
                      ]}
                    />
                  )}
                />
              </div>
              {adjustPlan === 'Change Plan' && (
                <div className='w-[10rem] mb-3'>
                  <Label className='!font-normal'>Interval</Label>
                  <Controller
                    name='preferred_plan'
                    control={control}
                    render={({ field }) => (
                      <Radio
                        className='w-full mt-2 h-[38px]'
                        {...field}
                        options={[
                          { value: '3 month', label: '3 month' },
                          { value: '9 month', label: '9 month' },
                          { value: 'Month to month', label: 'Month to month' }
                        ]}
                      />
                    )}
                  />
                </div>
              )}
              {adjustPlan === 'Change Plan' &&
                (selectedInterval === '3 month' ||
                  selectedInterval === '9 month') && (
                  <div className='mx-4'>
                    <Label className='!font-normal mb-1.5'>Goals</Label>
                    <Controller
                      name='goals'
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder='Select Goal'
                          {...field}
                          className='w-full h-[30px]'
                          options={[
                            { value: 'Maintenance', label: 'Maintenance' },
                            { value: 'Escalation', label: 'Escalation' },
                            { value: 'No preference', label: 'No preference' }
                          ]}
                        />
                      )}
                    />
                  </div>
                )}
            </div>
            <div
              className={`mt-3 grid ${adjustMedicationDose === 'switch_medication' ? 'grid-cols-3' : 'grid-cols-2'}`}
            >
              <div className='mb-4'>
                <Label className='!font-normal'>Adjust Medication Dose</Label>
                <Controller
                  name='dose'
                  control={control}
                  render={({ field }) => (
                    <Radio
                      className='w-full mt-2 h-[38px]'
                      {...field}
                      options={[
                        ...getDoseOptions(
                          String(info?.medication),
                          Number(info?.dose)
                        ),
                        {
                          label: 'Switch Medication',
                          value: 'switch_medication'
                        }
                      ]}
                    />
                  )}
                />
              </div>
              {adjustMedicationDose === 'switch_medication' && (
                <div>
                  <Label className='!font-normal'>Select Medication</Label>
                  <Controller
                    name='medication'
                    control={control}
                    render={({ field }) => (
                      <Radio
                        className='w-full mt-2 h-[38px]'
                        {...field}
                        options={[
                          { value: 'Semaglutide', label: 'Semaglutide' },
                          { value: 'Tirzepatide', label: 'Tirzepatide' }
                        ]}
                      />
                    )}
                  />
                </div>
              )}
              {adjustMedicationDose === 'switch_medication' &&
                selectedMedication && (
                  <div>
                    <Label className='!font-normal'>Dose</Label>
                    <Controller
                      name='switch_med_dose'
                      control={control}
                      render={({ field }) => (
                        <Radio
                          className='w-full mt-2 h-[38px]'
                          {...field}
                          options={
                            selectedMedication === 'Semaglutide'
                              ? semaglutideDoseOptions
                              : tirzepatideDoseOptions
                          }
                        />
                      )}
                    />
                  </div>
                )}
            </div>

            <div className='mb-2'>
              <Label className='!font-normal'>Side Effects</Label>
              <Controller
                name='side_effects'
                control={control}
                render={({ field }) => (
                  <Textarea type='text' row={2} className='mt-2' {...field} />
                )}
              />
            </div>
            <div className='mb-2'>
              <Label className='!font-normal mb-2'>
                <Label className='!font-normal mb-2'>
                  <span className='flex flex-row'>
                    <span>Message to Patient</span>
                    {isRefreshPending &&
                    refreshingColumn === 'message_to_patient' ? (
                      <span className='text-orange px-2 text-sm mt-[3px]'>
                        Refreshing...
                      </span>
                    ) : (
                      <RedoOutlined
                        className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                        onClick={() => {
                          refreshAIPrompts('message_to_patient');
                          setRefreshingColumn('message_to_patient');
                        }}
                      />
                    )}
                  </span>
                </Label>
              </Label>
              <Controller
                name='message_to_patient'
                control={control}
                render={({ field }) => <WysiwygEditor {...field} />}
              />
            </div>
          </div>
          <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
            <button
              type='button'
              className='!w-fit py-3 px-8 bg-grey text-white py-2 rounded-lg font-semibold'
              onClick={() => setIsClose()}
            >
              Cancel
            </button>
            <Button className='!w-fit py-3 px-8' isLoading={isPending}>
              Submit
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
