import { useMutation, useQueryClient } from '@tanstack/react-query';
import Button from 'components/button/Button';
import DatePicker from 'components/datepicker/DatePicker';
import Input from 'components/input/Input';
import { Label } from 'components/label/Label';
import Select from 'components/select/Select';
import dayjs, { Dayjs } from 'dayjs';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CheckInsType } from 'types/checkins';
import { request } from 'utils/api';
import formatDate from 'utils/helpers/date';

interface CheckInsFormInputs {
  date?: string;
  weight?: string | number;
  satisfaction?: string | number | null;
  medication?: string | null;
  last_vial_used?: string;
  adjusted_dose?: number | string;
  adjusted_units?: number | string;
  placed_order_date?: string;
  client_record_id?: string;
  mg_used?: string | number;
  mg_left?: string | number;
  weeks_left?: string | number;
  anticipated_order_date_1?: string;
  anticipated_order_date_2?: string;
  anticipated_order_date_3?: string;
}

const handleUpdateCheckIns = async (
  payload: CheckInsFormInputs,
  checkInsId: string
) => {
  const response = await request({
    url: `check-ins/${checkInsId}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

const handleCreateCheckIns = async (payload: CheckInsFormInputs) => {
  const response = await request({
    url: 'check-ins',
    method: 'POST',
    data: payload
  });
  return response;
};

export default function CheckInsForm({
  info,
  setIsClose
}: {
  info?: CheckInsType;
  setIsClose: any;
}) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields, errors }
  } = useForm<CheckInsFormInputs>({
    values: info
      ? {
          date: formatDate(info.date),
          weight: info.weight,
          satisfaction: info.satisfaction,
          medication: info.medication,
          last_vial_used: info.last_vial_used,
          adjusted_dose: info.adjusted_dose,
          adjusted_units: info.adjusted_units,
          placed_order_date: formatDate(info.placed_order_date),
          mg_used: info.mg_used,
          mg_left: info.mg_left,
          weeks_left: info.weeks_left,
          anticipated_order_date_1: info.anticipated_order_date_1,
          anticipated_order_date_2: info.anticipated_order_date_2,
          anticipated_order_date_3: info.anticipated_order_date_3
        }
      : {
          date: '',
          weight: '',
          satisfaction: null,
          medication: null,
          last_vial_used: '',
          adjusted_dose: '',
          adjusted_units: '',
          placed_order_date: '',
          mg_used: '',
          mg_left: '',
          weeks_left: '',
          anticipated_order_date_1: '',
          anticipated_order_date_2: '',
          anticipated_order_date_3: ''
        }
  });

  const { mutateAsync: updateCheckIns, isPending: isUpdatePending } =
    useMutation({
      mutationFn: (data: CheckInsFormInputs) =>
        handleUpdateCheckIns(data, String(info?.id)),
      onSuccess(data) {
        if (data !== undefined) {
          reset();
          queryClient.invalidateQueries({ queryKey: ['checkIns'] });
          queryClient.refetchQueries({ queryKey: ['allCheckIns'] });
          setIsClose();
        }
      }
    });

  const { mutateAsync: createCheckIns, isPending: isCreatePending } =
    useMutation({
      mutationFn: (data: CheckInsFormInputs) => handleCreateCheckIns(data),
      onSuccess(data) {
        if (data !== undefined) {
          reset();
          queryClient.invalidateQueries({ queryKey: ['checkIns'] });
          setIsClose();
        }
      }
    });

  const onSubmit = (data: CheckInsFormInputs) => {
    const dirtyData: Partial<CheckInsFormInputs> = {};

    Object.keys(data).forEach((key) => {
      const objKey = key as keyof CheckInsFormInputs;

      if (dirtyFields[objKey]) {
        const value = data[objKey];
        if (typeof value === 'string' || value === undefined) {
          dirtyData[objKey] = value;
        } else if (value !== null) {
          dirtyData[objKey] = String(value);
        }
      }
    });
    if (info) {
      updateCheckIns(dirtyData);
    } else {
      createCheckIns({ ...dirtyData, client_record_id: id });
    }
  };

  const isPending = isUpdatePending || isCreatePending;

  return (
    <>
      <p className='text-base font-semibold'>Check Ins Detail</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-2 mt-3.5 gap-6 mb-20'>
          <div>
            <Label className='!font-normal'>Date*</Label>
            <Controller
              control={control}
              name='date'
              rules={{ required: 'Date is required' }}
              render={({ field }) => (
                <DatePicker
                  className='!px-2.5 !py-1.5'
                  format='MM-DD-YYYY'
                  {...field}
                  onChange={(date: Dayjs | null) => {
                    field.onChange(date ? date.format('MM/DD/YYYY') : null);
                  }}
                  value={field.value ? dayjs(field.value) : null}
                />
              )}
            />
            <p className='text-red text-sm'>{errors?.date?.message}</p>
          </div>
          <div>
            <Label className='!font-normal'>Last Vial Use*</Label>
            <Controller
              name='last_vial_used'
              rules={{ required: 'Last Vial Used is required' }}
              control={control}
              render={({ field }) => (
                <Select
                  className='mt-2 w-full h-[35px]'
                  {...field}
                  placeholder='Select'
                  options={[
                    {
                      value:
                        'I received a vial but still have enough in my current one. I have not opened it',
                      label:
                        'I received a vial but still have enough in my current one. I have not opened it'
                    },
                    ...Array(8)
                      .fill(0)
                      .map((_, index) => ({
                        label: index + 1,
                        value: index + 1
                      })),
                    {
                      value: 'I did not received a vial last month',
                      label: 'I did not received a vial last month'
                    }
                  ]}
                />
              )}
            />
            <p className='text-red text-sm'>
              {errors?.last_vial_used?.message}
            </p>
          </div>
          <div>
            <Label className='!font-normal'>Weight</Label>
            <Controller
              name='weight'
              control={control}
              render={({ field }) => (
                <Input type='number' className='mt-2' {...field} />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Satisfaction</Label>
            <Controller
              name='satisfaction'
              control={control}
              render={({ field }) => (
                <Select
                  className='mt-2 w-full h-[35px]'
                  {...field}
                  placeholder='Select'
                  options={Array(10)
                    .fill(0)
                    .map((_, index) => ({
                      value: index + 1,
                      label: index + 1
                    }))}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Medications</Label>
            <Controller
              name='medication'
              control={control}
              render={({ field }) => (
                <Select
                  className='w-full mt-2 h-[38px]'
                  {...field}
                  placeholder='Select'
                  options={[
                    { value: 'semaglutide', label: 'Semaglutide' },
                    { value: 'tirzepatide', label: 'Tirzepatide' }
                  ]}
                />
              )}
            />
          </div>

          <div>
            <Label className='!font-normal'>Adjusted Dose</Label>
            <Controller
              name='adjusted_dose'
              control={control}
              render={({ field }) => (
                <Input type='number' className='mt-2' {...field} />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Adjusted Units</Label>
            <Controller
              name='adjusted_units'
              control={control}
              render={({ field }) => (
                <Input type='number' className='mt-2' {...field} />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Placed Order Date</Label>
            <Controller
              control={control}
              name='placed_order_date'
              render={({ field }) => (
                <DatePicker
                  className='!px-2.5 !py-1.5'
                  format='MM-DD-YYYY'
                  {...field}
                  onChange={(date: Dayjs | null) => {
                    field.onChange(date ? date.format('MM/DD/YYYY') : null);
                  }}
                  value={field.value ? dayjs(field.value) : null}
                />
              )}
            />
          </div>
          {info && (
            <>
              <div>
                <Label className='!font-normal'>Mg Used</Label>
                <Controller
                  name='mg_used'
                  control={control}
                  render={({ field }) => (
                    <Input
                      disabled
                      type='number'
                      className='mt-2 cursor-not-allowed text-silver'
                      {...field}
                    />
                  )}
                />
              </div>
              <div>
                <Label className='!font-normal'>Mg Left</Label>
                <Controller
                  name='mg_left'
                  control={control}
                  render={({ field }) => (
                    <Input
                      disabled
                      type='number'
                      className='mt-2 cursor-not-allowed text-silver'
                      {...field}
                    />
                  )}
                />
              </div>
              <div>
                <Label className='!font-normal'>Weels Left</Label>
                <Controller
                  name='weeks_left'
                  control={control}
                  render={({ field }) => (
                    <Input
                      disabled
                      type='number'
                      className='mt-2 cursor-not-allowed text-silver'
                      {...field}
                    />
                  )}
                />
              </div>
              <div>
                <Label className='!font-normal'>Anticipated Order Date 1</Label>
                <Controller
                  name='anticipated_order_date_1'
                  control={control}
                  render={({ field }) => (
                    <Input
                      disabled
                      type='text'
                      className='mt-2 cursor-not-allowed text-silver'
                      {...field}
                    />
                  )}
                />
              </div>
              <div>
                <Label className='!font-normal'>Anticipated Order Date 2</Label>
                <Controller
                  name='anticipated_order_date_2'
                  control={control}
                  render={({ field }) => (
                    <Input
                      disabled
                      type='text'
                      className='mt-2 cursor-not-allowed text-silver'
                      {...field}
                    />
                  )}
                />
              </div>
              <div>
                <Label className='!font-normal'>Anticipated Order Date 3</Label>
                <Controller
                  name='anticipated_order_date_3'
                  control={control}
                  render={({ field }) => (
                    <Input
                      disabled
                      type='text'
                      className='mt-2 cursor-not-allowed text-silver'
                      {...field}
                    />
                  )}
                />
              </div>
            </>
          )}
        </div>
        <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
          <Button className='!w-fit py-3 px-8' isLoading={isPending}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}
