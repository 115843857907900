import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { ColumnDef, RowSelectionState } from '@tanstack/react-table';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import IndeterminateCheckbox from 'components/indeterminateCheckbox/IndeterminateCheckbox';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from 'components/button/Button';
import { FolderAddOutlined, SearchOutlined } from '@ant-design/icons';
import Select from 'components/select/Select';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import { joinName } from 'utils/helpers/name';
import Tooltip from 'components/tooltip/Tooltip';
import { Input, Spin } from 'antd';
import formatDate from 'utils/helpers/date';
import { TasksResponse } from 'types/tasks';
import { downloadXLSX, transformCSVData } from 'utils/helpers/csvDownloader';
import { FaSort } from 'react-icons/fa';
import PopupConfirm from 'components/popupConfirm/PopupConfirm';
import { Label } from 'components/label/Label';

interface TasksFilterInput {
  name: string;
  email: string;
  completed: string;
  consultant_id: string;
  archived: string;
}

interface TasksSortingDetails {
  sort_by: string;
  sort_order: string;
}

const getTasks = async (
  currentPage: number,
  params?: TasksFilterInput,
  download?: boolean,
  sortingDetails?: TasksSortingDetails
) => {
  const data = await request({
    url: '/tasks',
    method: 'GET',
    params: {
      page: currentPage,
      download: download,
      ...params,
      ...sortingDetails
    }
  });
  return data;
};

const getUsers = async () => {
  const data = await request({
    url: '/users',
    method: 'GET',
    params: { page: 1, per_page: 30 }
  });
  return data;
};

const handleSessionCreation = async (id: string) => {
  const data = await request({
    url: `/tasks/virtual-session/${id}`,
    method: 'PUT',
    data: { task_ids: [id] }
  });
  return data;
};

const handleArchiveData = async (Ids: string[]) => {
  const response = await request({
    url: 'tasks/status-update',
    method: 'PUT',
    data: { ids: Ids }
  });
  return response;
};

const handleBulkSessionCreation = async (selectedTaskIds: string[]) => {
  const data = await request({
    url: `/tasks/virtual-session/${selectedTaskIds[0]}`,
    method: 'PUT',
    data: { task_ids: selectedTaskIds }
  });
  return data;
};

export default function TasksPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [tasksFilters, setTasksFilters] = useState({
    name: '',
    email: '',
    completed: '',
    consultant_id: '',
    archived: 'false'
  });
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);
  const [processingId, setProcessingId] = useState('');
  const [downloadingData, setDownloadingData] = useState(false);
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'asc'
  });
  const queryClient = useQueryClient();

  const { data: tasksData, isLoading } = useQuery({
    queryKey: ['tasks', currentPage, tasksFilters, sortingDetails],
    queryFn: () =>
      getTasks(currentPage, tasksFilters, undefined, sortingDetails)
  });

  const { data: usersData } = useQuery({
    queryKey: ['usersData'],
    queryFn: () => getUsers()
  });

  const { mutateAsync: createSession, isPending: isSessionCreationPending } =
    useMutation({
      mutationFn: (id: string) => handleSessionCreation(id),
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ['tasks'] });
        queryClient.refetchQueries({ queryKey: ['tasks'] });
      }
    });

  const {
    mutateAsync: createBulkSessions,
    isPending: isBulkSessionCreationPending
  } = useMutation({
    mutationFn: () => handleBulkSessionCreation(selectedRows),
    onSuccess() {
      setRowSelection({});
      queryClient.invalidateQueries({ queryKey: ['tasks'] });
      queryClient.refetchQueries({ queryKey: ['tasks'] });
    }
  });

  const { mutateAsync: archiveData, isPending: isArchivePending } = useMutation(
    {
      mutationFn: (Ids: string[]) => handleArchiveData(Ids),
      onSuccess() {
        setRowSelection({});
        queryClient.invalidateQueries({ queryKey: ['tasks_archive'] });
        queryClient.refetchQueries({ queryKey: ['tasks'] });
      }
    }
  );

  const tasks = useMemo(() => {
    return tasksData?.tasks ?? [];
  }, [tasksData?.tasks]);

  const { control, handleSubmit, reset } = useForm<TasksFilterInput>({
    defaultValues: {}
  });

  const downloadTasksCSV = async () => {
    setDownloadingData(true);
    let response = await getTasks(currentPage, tasksFilters, true);
    if (response) {
      downloadXLSX(transformCSVData(response), 'tasks');
      setDownloadingData(false);
    }
  };

  const onSubmit: SubmitHandler<TasksFilterInput> = (data) => {
    setTasksFilters(data);
    setCurrentPage(1);
  };

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <div className='flex justify-between items-center'>
          <h1 className='text-xl font-semibold mb-2'>Tasks</h1>
          <div className='flex flex-row gap-2'>
            <button
              disabled={!(selectedRows.length > 1) || isArchivePending}
              className='border border-1 border-orange px-2 py-1 bg-orange text-white rounded-lg w-32 disabled:opacity-50 disabled:cursor-not-allowed'
              onClick={() => archiveData([...selectedRows])}
            >
              {isArchivePending ? 'Archiving...' : 'Archive'}
            </button>
            <button
              className='border border-1 border-orange py-1 bg-orange text-white rounded-lg disabled:opacity-50 disabled:cursor-not-allowed w-36'
              disabled={
                !(selectedRows.length > 1) || isBulkSessionCreationPending
              }
              onClick={() => createBulkSessions()}
            >
              {isBulkSessionCreationPending ? <Spin /> : 'Create Sessions'}
            </button>
            <button
              disabled={downloadingData}
              className={`border border-1 border-orange px-2 py-1 bg-orange text-white rounded-lg w-32 ${downloadingData && 'opacity-50'}`}
              onClick={() => downloadTasksCSV()}
            >
              {downloadingData ? 'Downloading...' : 'Download'}
            </button>
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='filters flex gap-6 items-center my-4'>
            <div className='w-40'>
              <Label className='text-sm !font-normal m-1'>Name</Label>
              <Controller
                name='name'
                control={control}
                render={({ field }) => <Input type='text' {...field} />}
              />
            </div>
            <div className='w-44'>
              <Label className='text-sm !font-normal m-1'>Email</Label>
              <Controller
                name='email'
                control={control}
                render={({ field }) => <Input type='email' {...field} />}
              />
            </div>
            <div>
              <Label className='text-sm !font-normal m-1'>Completed</Label>
              <Controller
                name='completed'
                control={control}
                render={({ field }) => (
                  <Select
                    options={[
                      {
                        value: 'true',
                        label: 'Yes'
                      },
                      {
                        value: 'false',
                        label: 'No'
                      }
                    ]}
                    className='w-[150px]'
                    {...field}
                  />
                )}
              />
            </div>
            <div>
              <Label className='text-sm !font-normal m-1'>Consultant</Label>
              <Controller
                name='consultant_id'
                control={control}
                render={({ field }) => (
                  <Select
                    options={usersData?.users
                      ?.filter(
                        (data: { pb_consultant_id: string }) =>
                          !!data.pb_consultant_id
                      )
                      .map(
                        (data: {
                          pb_consultant_id: string;
                          first_name: string;
                        }) => ({
                          value: data.pb_consultant_id,
                          label: data.first_name
                        })
                      )}
                    className='w-[150px]'
                    {...field}
                  />
                )}
              />
            </div>
            <div>
              <Label className='text-sm !font-normal m-1'>Archived</Label>
              <Controller
                name='archived'
                defaultValue={tasksFilters.archived}
                control={control}
                render={({ field }) => (
                  <Select
                    options={[
                      {
                        value: 'true',
                        label: 'Yes'
                      },
                      {
                        value: 'false',
                        label: 'No'
                      }
                    ]}
                    className='w-[150px]'
                    {...field}
                  />
                )}
              />
            </div>
            <Button className='w-max px-3.5 h-[38px] mt-5'>
              <SearchOutlined className='!text-white' />
            </Button>
            <button
              onClick={() =>
                reset({
                  name: '',
                  email: '',
                  completed: '',
                  consultant_id: '',
                  archived: ''
                })
              }
              className='border border-orange rounded py-2.5 px-3 mt-5'
            >
              <MdOutlineFilterAltOff className='text-orange' />
            </button>
          </div>
        </form>
        <div className='overflow-auto h-[calc(100vh_-_280px)]'>
          <DataTable
            isLoading={isLoading}
            data={tasks}
            setRowSelection={setRowSelection}
            rowSelection={rowSelection}
            columns={columns(
              createSession,
              isSessionCreationPending,
              setProcessingId,
              processingId,
              sortingDetails,
              setSortingDetails,
              archiveData
            )}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={tasksData?.pagination.total_count}
        totalPage={tasksData?.pagination.total_pages}
        perPage={tasksData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

const columns = (
  createSession: any,
  isSessionCreationPending: boolean,
  setProcessingId: any,
  processingId: string,
  sortingDetails: any,
  setSortingDetails: any,
  archiveData: (ids: string[]) => void
): ColumnDef<TasksResponse>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler()
        }}
      />
    ),
    cell: ({ row }) => (
      <div className='px-1'>
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler()
          }}
        />
      </div>
    )
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Created At</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'created_at',
              sort_order:
                sortingDetails?.sort_by === 'created_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Name',
    accessorKey: 'first_name',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/clients/${info.row.original.client_record_id}`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {joinName(info.getValue<string>(), info.row.original.last_name)}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email'
  },
  {
    header: 'Consultant',
    accessorKey: 'consultant'
  },
  {
    header: 'Title',
    accessorKey: 'title',
    cell: (info) => (
      <span>
        <Tooltip title={String(info.getValue())}>
          {String(info.getValue()).length < 30
            ? String(info.getValue())
            : `${String(info.getValue()).slice(0, 30)}...`}
        </Tooltip>
      </span>
    )
  },
  {
    header: 'Completed',
    accessorKey: 'completed',
    cell: (info) => <span>{info.getValue() ? 'Yes' : 'No'}</span>
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Due Date</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'due_date',
              sort_order:
                sortingDetails?.sort_by === 'due_date' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'due_date',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Message</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'message',
              sort_order:
                sortingDetails?.sort_by === 'message' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'message'
  },
  {
    header: 'Archived',
    accessorKey: 'archived',
    cell: (info) => <span>{info.getValue() ? 'Yes' : 'No'}</span>
  },
  {
    header: 'Action',
    accessorKey: 'id',
    cell: (info) => {
      const isPending =
        isSessionCreationPending && processingId === info.row.original.id;
      return (
        <div className='flex flex-row'>
          <button
            disabled={isSessionCreationPending || info.row.original.completed}
            className={
              'text-orange font-semibold rounded-lg disabled:opacity-50 disabled:cursor-not-allowed underline disabled:no-underline text-nowrap'
            }
            onClick={() => {
              setProcessingId(String(info.row.original.id));
              createSession(String(info.row.original.id));
            }}
          >
            {info.row.original.completed ? (
              <Tooltip title='Task Completed.'>Create Session</Tooltip>
            ) : isPending ? (
              <Spin />
            ) : (
              'Create Session'
            )}
          </button>
          {!info.row.original.archived && (
            <PopupConfirm
              title='Archive Record'
              description='Are you sure you want to archive?'
              className='ml-2 text-base disabled:opacity-50'
              onConfirm={() => archiveData([String(info.getValue())])}
            >
              <Tooltip title='Move to archives.'>
                <FolderAddOutlined className='text-orange cursor-pointer' />
              </Tooltip>
            </PopupConfirm>
          )}
        </div>
      );
    }
  }
];
