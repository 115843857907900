/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import Search from 'components/search/Search';
import Select from 'components/select/Select';
import { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { useDebounce } from 'use-debounce';
import { DeleteFilled, EditFilled } from '@ant-design/icons';
import formatDate from 'utils/helpers/date';

const getKnowledgeBaseApprovals = async (currentPage: number) => {
  const data = await request({
    url: '/knowledge-base-approvals',
    method: 'GET',
    params: { page: currentPage }
  });
  return data;
};

const deleteKnowledgeBaseApprovals = async (id: string) => {
  const data = await request({
    url: `/knowledge-base-approvals/${id}`,
    method: 'DELETE'
  });
  return data;
};

export default function KnowledgeBaseApprovalsPage() {
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [debounceSearchText] = useDebounce(searchText, 1000);

  const { data: knowledgeBaseApprovalsData, isLoading } = useQuery({
    queryKey: ['knowledgeBaseApprovals', currentPage, debounceSearchText],
    queryFn: () => getKnowledgeBaseApprovals(currentPage)
  });

  const { mutateAsync: deleteRecord } = useMutation({
    mutationFn: (id: string) => deleteKnowledgeBaseApprovals(id),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['knowledgeBaseApprovals'] });
    }
  });

  const columns = [
    {
      header: 'Category',
      accessorKey: 'category'
    },
    {
      header: 'Question',
      accessorKey: 'question'
    },
    {
      header: 'Answer',
      accessorKey: 'answer'
    },
    {
      header: 'Created At',
      accessorKey: 'created_at',
      cell: (info: any) => formatDate(info.getValue())
    },
    {
      header: 'Actions',
      accessorKey: 'id',
      cell: (info: any) => (
        <div className='flex gap-4 items-center'>
          <EditFilled className='text-lg w-6 h-6 text-orange' />
          <DeleteFilled
            onClick={() => deleteRecord(info.row.original.id)}
            className='text-lg w-6 h-6 text-red cursor-pointer'
          />
        </div>
      )
    }
  ];

  const knowledgeBaseApprovals = useMemo(() => {
    return knowledgeBaseApprovalsData?.knowledge_base_approvals ?? [];
  }, [knowledgeBaseApprovalsData?.knowledge_base_approvals]);

  return (
    <h1 className='font-bold text-center text-silver text-3xl mt-10'>
      Coming Soon...
    </h1>
  );
  // return (
  //   <div className='p-8 bg-[#FAFBFC]'>
  //     <div className='flex gap-6 items-center'>
  //       <Select
  //         placeholder='Category'
  //         options={[
  //           { value: 'general', label: 'General' },
  //           { value: 'pricing', label: 'Pricing' },
  //           { value: 'all', label: 'All' }
  //         ]}
  //         className='w-[129px]'
  //       />
  //       <Search
  //         placeholder='Search'
  //         type='text'
  //         value={searchText}
  //         onChange={(e) => setSearchText(e.target.value)}
  //       />
  //     </div>
  //     <div className='border border-light-grey rounded-xl p-8 mt-6 bg-white'>
  //       <h5 className='text-xl font-semibold mb-5'>Order_Related</h5>
  //       <DataTable
  //         isLoading={isLoading}
  //         data={knowledgeBaseApprovals}
  //         columns={columns}
  //         isVerticalBorderEnabled={true}
  //       />
  //     </div>
  //     <Pagination
  //       isLoading={isLoading}
  //       totalCount={knowledgeBaseApprovalsData?.pagination.total_count}
  //       totalPage={knowledgeBaseApprovalsData?.pagination.total_pages}
  //       perPage={knowledgeBaseApprovalsData?.pagination.per_page}
  //       currentPage={currentPage}
  //       setCurrentPage={setCurrentPage}
  //     />
  //   </div>
  // );
}
