import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { ColumnDef, RowSelectionState } from '@tanstack/react-table';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import Drawer from 'components/drawer/Drawer';
import {
  EyeOutlined,
  FolderAddOutlined,
  LinkOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { VirtualFollowupsResponse } from 'types/virtualFollowups';
import VirtualFollowupsForm from './components/VirtualFollowupsForm';
import formatDate from 'utils/helpers/date';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Button from 'components/button/Button';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import Input from 'components/input/Input';
import Select from 'components/select/Select';
import paths from 'constants/path';
import PopupConfirm from 'components/popupConfirm/PopupConfirm';
import Tooltip from 'components/tooltip/Tooltip';
import IndeterminateCheckbox from 'components/indeterminateCheckbox/IndeterminateCheckbox';

interface VirtualFollowupsFilter {
  form_id: string;
  name: string;
  status: string;
  consultant_id: string;
}

const getVirtualFollowups = async (
  currentPage: number,
  params?: VirtualFollowupsFilter
) => {
  const data = await request({
    url: '/form-requests',
    method: 'GET',
    params: { page: currentPage, ...params }
  });
  return data;
};

const getUsers = async () => {
  const data = await request({
    url: '/users',
    method: 'GET',
    params: { page: 1, per_page: 30 }
  });
  return data;
};

const handleArchiveData = async (Ids: string[]) => {
  const response = await request({
    url: 'form-requests/status-update',
    method: 'PUT',
    data: { ids: Ids }
  });
  return response;
};

export default function VirtualFollowups() {
  const [currentPage, setCurrentPage] = useState(1);
  const [virtualFollowupsFilters, setVirtualFollowupsFilters] = useState({
    form_id: '6517100f59761126ff10ba53',
    name: '',
    status: '',
    consultant_id: ''
  });
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);

  const queryClient = useQueryClient();

  const { data: VirtualFollowupsData, isLoading } = useQuery({
    queryKey: ['virtual_followups', currentPage, virtualFollowupsFilters],
    queryFn: () => getVirtualFollowups(currentPage, virtualFollowupsFilters)
  });

  const { data: usersData } = useQuery({
    queryKey: ['usersData'],
    queryFn: () => getUsers()
  });

  const { mutateAsync: archiveData, isPending: isArchivePending } = useMutation(
    {
      mutationFn: (Ids: string[]) => handleArchiveData(Ids),
      onSuccess() {
        setRowSelection({});
        queryClient.invalidateQueries({
          queryKey: ['virtual_followups_archive']
        });
        queryClient.refetchQueries({ queryKey: ['virtual_followups'] });
      }
    }
  );

  const { control, handleSubmit, reset } = useForm<VirtualFollowupsFilter>({
    defaultValues: {}
  });

  const virtualFollowUpsMemorizedData = useMemo(() => {
    return VirtualFollowupsData?.form_requests ?? [];
  }, [VirtualFollowupsData?.form_requests]);

  const onSubmit: SubmitHandler<VirtualFollowupsFilter> = (data) => {
    setVirtualFollowupsFilters(data);
    setCurrentPage(1);
  };

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold'>Virtual Follow-ups</h1>
        <div className='flex justify-between items-center'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='filters flex gap-6 items-center my-4'>
              <Controller
                name='name'
                control={control}
                render={({ field }) => (
                  <Input placeholder='Name' type='text' {...field} />
                )}
              />
              <Controller
                name='status'
                control={control}
                render={({ field }) => (
                  <Select
                    placeholder='Select Status'
                    {...field}
                    className='w-[150px] h-[38px]'
                    options={[
                      { label: 'Pending', value: 'Pending' },
                      { label: 'Reviewed', value: 'Reviewed' },
                      { label: 'Archive', value: 'Archive' }
                    ]}
                  />
                )}
              />
              <Controller
                name='consultant_id'
                control={control}
                render={({ field }) => (
                  <Select
                    placeholder='Consultant'
                    options={usersData?.users
                      ?.filter(
                        (data: { pb_consultant_id: string }) =>
                          !!data.pb_consultant_id
                      )
                      .map(
                        (data: {
                          pb_consultant_id: string;
                          first_name: string;
                        }) => ({
                          value: data.pb_consultant_id,
                          label: data.first_name
                        })
                      )}
                    className='w-[150px] h-[38px]'
                    {...field}
                  />
                )}
              />
              <Button className='w-max px-3.5 h-[38px]'>
                <SearchOutlined className='!text-white' />
              </Button>
              <button
                onClick={() =>
                  reset({
                    form_id: '6517100f59761126ff10ba53',
                    name: '',
                    status: undefined
                  })
                }
                className='border border-orange rounded py-2.5 px-3'
              >
                <MdOutlineFilterAltOff className='text-orange' />
              </button>
            </div>
          </form>
          <button
            disabled={!(selectedRows.length > 1) || isArchivePending}
            className='border border-1 border-orange px-2 py-1 bg-orange text-white rounded-lg w-32 disabled:opacity-50 disabled:cursor-not-allowed'
            onClick={() => archiveData([...selectedRows])}
          >
            {isArchivePending ? 'Archiving...' : 'Archive'}
          </button>
        </div>
        <div className='overflow-auto h-[calc(100vh_-_267px)]'>
          <DataTable
            isLoading={isLoading}
            data={virtualFollowUpsMemorizedData}
            setRowSelection={setRowSelection}
            rowSelection={rowSelection}
            columns={columns(archiveData)}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={VirtualFollowupsData?.pagination.total_count}
        totalPage={VirtualFollowupsData?.pagination.total_pages}
        perPage={VirtualFollowupsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

const columns = (
  archiveData: (ids: string[]) => void
): ColumnDef<VirtualFollowupsResponse>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler()
        }}
      />
    ),
    cell: ({ row }) => (
      <div className='px-1'>
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler()
          }}
        />
      </div>
    )
  },
  {
    header: 'Name',
    accessorKey: 'name',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/clients/${info.row.original.id}`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {info.getValue<string>()}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email',
    cell: (info) => (
      <Link
        to={paths.customer(String(info.row.original.client_record_id))}
        className='text-orange underline underline-offset-4'
      >
        {info.getValue<string>()}
      </Link>
    )
  },
  {
    header: 'Follow-up Date',
    accessorKey: 'followup_date',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Current Medication',
    accessorKey: 'current_medication',
    cell: (info) =>
      info.getValue() ? (
        <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center capitalize'>
          {info.getValue() as string}
        </p>
      ) : (
        '---'
      )
  },
  {
    header: 'Weight(lbs)',
    accessorKey: 'weight'
  },
  {
    header: 'Satisfaction',
    accessorKey: 'satisfaction'
  },
  {
    header: 'Form Completed',
    accessorKey: 'completed',
    cell: (info) => (info.row.original.completed ? 'Yes' : 'No')
  },
  {
    header: 'Draft Date',
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Status',
    accessorKey: 'status',
    cell: (info) => (
      <span className='text-neutral-700'>{info.getValue() as string}</span>
    )
  },
  {
    header: 'Consultant',
    accessorKey: 'consultant'
  },
  {
    header: 'Action',
    accessorKey: 'id',
    cell: (info) => (
      <div className='flex items-center gap-3 text-base'>
        <Drawer
          styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
          width='90%'
          button={
            <Tooltip title='Review details.'>
              <EyeOutlined className='mr-1 cursor-pointer' />
            </Tooltip>
          }
          closeIcon={null}
        >
          <VirtualFollowupsForm info={info.row.original} setIsClose={''} />
        </Drawer>
        <Link
          to={info.row.original.form_pb_link}
          target='_blank'
          className='text-orange underline-offset-4'
        >
          <Tooltip title='Redirect to Practice Better.'>
            <LinkOutlined className='cursor-pointer' />
          </Tooltip>
        </Link>
        {info.row.original.status !== 'Archive' && (
          <PopupConfirm
            title='Archive Record'
            description='Are you sure you want to archive?'
            onConfirm={() => archiveData([String(info.getValue())])}
          >
            <Tooltip title='Move to archives.'>
              <FolderAddOutlined className='text-orange cursor-pointer' />
            </Tooltip>
          </PopupConfirm>
        )}
      </div>
    )
  }
];
