import React from 'react';
import logo from 'assets/images/logo.svg';
import LoginForm from './components/LoginForm';

export default function Login() {
  return (
    <div className='grid grid-cols-2 h-screen'>
      <div className='flex items-center justify-center'>
        <img src={logo} alt='Logo' className='w-[437px] h-auto' />
      </div>
      <div className='flex flex-col justify-center w-2/3 ml-10'>
        <h2 className='text-3xl font-semibold mb-2.5'>Login</h2>
        <p className='text-grey mb-10'>
          Welcome back! Please log in to access your account.
        </p>
        <LoginForm />
      </div>
    </div>
  );
}
