import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { ColumnDef } from '@tanstack/react-table';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import Button from 'components/button/Button';
import {
  ArrowRightOutlined,
  EditOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Input from 'components/input/Input';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import formatDate from 'utils/helpers/date';
import RangePicker from 'components/rangePicker/RangePicker';
import dayjs from 'dayjs';
import { CheckInsType } from 'types/checkins';
import Drawer from 'components/drawer/Drawer';
import CheckInsForm from 'pages/customer/components/CheckInsForm';
import { Link } from 'react-router-dom';
import paths from 'constants/path';
import { Tooltip } from 'antd';

interface OrderFilterInput {
  tracking_number: string;
  email: string;
  date_range: string[] | null;
}

const getCheckIns = async (currentPage: number, params?: OrderFilterInput) => {
  const data = await request({
    url: '/check-ins',
    method: 'GET',
    params: { page: currentPage, ...params }
  });
  return data;
};

export default function CheckInsPage() {
  const [currentPage, setCurrentPage] = useState(1);

  const [orderFilters, setOrderFilters] = useState({
    tracking_number: '',
    email: '',
    date_range: ['']
  });

  const { data: checkInsData, isLoading } = useQuery({
    queryKey: ['allCheckIns', currentPage, orderFilters],
    queryFn: () => getCheckIns(currentPage, orderFilters)
  });

  const checkInsMemorizedData = useMemo(() => {
    return checkInsData?.check_ins ?? [];
  }, [checkInsData?.check_ins]);

  const { control, handleSubmit, reset } = useForm<OrderFilterInput>({
    defaultValues: {}
  });

  const onSubmit: SubmitHandler<OrderFilterInput | any> = (data) => {
    data.start_date = data.date_range?.[0];
    data.end_date = data.date_range?.[1];
    delete data.date_range;
    setOrderFilters(data);
    setCurrentPage(1);
  };

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold'>CheckIns</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='filters flex gap-6 items-center my-4'>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <Input placeholder='Email' type='email' {...field} />
              )}
            />
            <Controller
              control={control}
              name='date_range'
              render={({ field }) => (
                <RangePicker
                  className='!px-2.5 !py-1.5'
                  format='MM/DD/YYYY'
                  {...field}
                  onChange={(_, dateStrings: [string, string]) => {
                    console.log(dateStrings);
                    field.onChange(dateStrings);
                  }}
                  value={
                    field.value
                      ? [dayjs(field.value[0]), dayjs(field.value[1])]
                      : null
                  }
                />
              )}
            />
            <Button className='w-max px-3.5 h-[38px]'>
              <SearchOutlined className='!text-white' />
            </Button>
            <button
              onClick={() =>
                reset({
                  tracking_number: '',
                  email: '',
                  date_range: null
                })
              }
              className='border border-orange rounded py-2.5 px-3'
            >
              <MdOutlineFilterAltOff className='text-orange' />
            </button>
          </div>
        </form>
        <div className='overflow-auto h-[calc(100vh_-_267px)]'>
          <DataTable
            isLoading={isLoading}
            data={checkInsMemorizedData}
            columns={columns}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={checkInsData?.pagination.total_count}
        totalPage={checkInsData?.pagination.total_pages}
        perPage={checkInsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

const columns: ColumnDef<CheckInsType>[] = [
  {
    header: 'Customer Name',
    accessorKey: 'first_name',
    cell: (info) => (
      <a
        href={info.row.original.practice_better_profile}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {info.getValue() as string} {info.row.original.last_name}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email',
    cell: (info) => (
      <Link
        to={paths.customer(
          info.row.original.practice_better_profile.split('clients/')[1]
        )}
      >
        <p className='text-orange underline truncate max-w-32'>
          <Tooltip title={info.getValue<string>()}>
            {info.getValue<string>()}
          </Tooltip>
        </p>
      </Link>
    )
  },
  {
    header: 'Date',
    accessorKey: 'date',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Weight(lbs)',
    accessorKey: 'weight'
  },
  {
    header: 'Satisfaction',
    accessorKey: 'satisfaction'
  },
  {
    header: 'Medication',
    accessorKey: 'medication',
    cell: (info) =>
      info.getValue() ? (
        <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center capitalize'>
          {info.getValue() as string}
        </p>
      ) : (
        '---'
      )
  },
  {
    header: 'Uses/Last Vial',
    accessorKey: 'last_vial_used',
    cell: (info) => (
      <p className='truncate max-w-24 text-orange'>
        <Tooltip title={info.getValue() as string} placement='left'>
          {info.getValue() as string}
        </Tooltip>
      </p>
    )
  },
  {
    header: 'Adjusted Dose',
    accessorKey: 'adjusted_dose'
  },
  {
    header: 'Adjusted Unit',
    accessorKey: 'adjusted_units'
  },
  {
    header: 'Action',
    accessorKey: 'id',
    cell: (info) => (
      <div className='flex items-center gap-3'>
        <Drawer
          styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
          width='50%'
          button={
            <>
              <EditOutlined className='mr-1' />
              Edit
            </>
          }
          title='Edit Check Ins Details'
          closeIcon={<ArrowRightOutlined />}
        >
          <CheckInsForm info={info.row.original} setIsClose={''} />
        </Drawer>
        <Link
          to={paths.customer(
            info.row.original.practice_better_profile.split('clients/')[1]
          )}
          className='text-orange underline-offset-4'
        >
          View
        </Link>
      </div>
    )
  }
];
