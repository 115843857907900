import React from 'react';
import {
  Pagination as PaginationComponent,
  PaginationProps as PaginationComponentProps,
  Skeleton
} from 'antd';

interface PaginationProps {
  currentPage: number;
  totalPage: number;
  setCurrentPage: any;
  totalCount: number;
  perPage: number;
  isLoading?: boolean;
}

export default function Pagination({
  currentPage,
  totalPage,
  totalCount,
  perPage = 10,
  setCurrentPage,
  isLoading
}: PaginationProps) {
  if (isLoading) {
    return (
      <div className='flex justify-between mt-8'>
        <Skeleton.Button shape='square' size='small' />
        <div className='flex gap-2'>
          {[...Array(5)].map((_, index) => (
            <Skeleton.Avatar key={index} shape='square' size='small' />
          ))}
        </div>
      </div>
    );
  }
  if (totalPage <= 1) {
    return null;
  }
  return (
    <PaginationComponent
      className='my-2'
      showTotal={(total, range) =>
        `Showing ${range[0]} to ${range[1]} of ${total} entries`
      }
      current={currentPage}
      onChange={(page) => setCurrentPage(page)}
      align='end'
      showSizeChanger={false}
      defaultCurrent={1}
      pageSize={perPage}
      total={totalCount}
      itemRender={itemRender}
    />
  );
}

const itemRender: PaginationComponentProps['itemRender'] = (
  _,
  type,
  originalElement
) => {
  if (type === 'prev') {
    return <p>Previous</p>;
  }
  if (type === 'next') {
    return <p>Next</p>;
  }
  return originalElement;
};
