import React, { forwardRef } from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type: string;
  placeholder?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  { type, placeholder, className, ...props },
  ref
) {
  return (
    <div>
      <input
        type={type}
        className={`w-full px-2.5 py-1.5 border rounded-lg focus:outline-none focus:border-orange ${className}`}
        placeholder={placeholder}
        ref={ref}
        {...props}
      />
    </div>
  );
});

export default Input;
