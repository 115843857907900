import React from 'react';
import { NavLink } from 'react-router-dom';

interface NavLinkItemProps {
  path: string;
  children: React.ReactNode;
}
export default function NavLinkItem({ path, children }: NavLinkItemProps) {
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        `relative text-sm text-light-grey ${isActive && 'active'}`
      }
    >
      {children}
    </NavLink>
  );
}
