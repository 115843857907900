import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Input } from 'antd';
import Button from 'components/button/Button';
import { Label } from 'components/label/Label';
import Select from 'components/select/Select';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { request } from 'utils/api';

interface PackagesFormInputs {
  medication?: string;
  pb_package_name?: any;
  payment_plan_duration?: number;
  payment_plan_amount?: number;
  plan?: string;
  invoice_amount?: number;
}

type Item = {
  name: string;
  selected: boolean;
};

const handleUpdatePackage = async (payload: PackagesFormInputs, id: string) => {
  const response = await request({
    url: `/packages/${id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

const handleAddPackage = async (
  payload: PackagesFormInputs,
  clientId: string
) => {
  const response = await request({
    url: '/packages',
    method: 'POST',
    data: payload,
    params: { client_id: clientId }
  });
  return response;
};

export default function PackageForm({ info, setIsClose }: any) {
  const { id } = useParams();

  const queryClient = useQueryClient();
  const {
    control,
    handleSubmit,
    reset,
    formState: { dirtyFields }
  } = useForm<PackagesFormInputs>({
    values: info
      ? {
          pb_package_name: getSelectedNames(info.pb_package_name),
          medication: info.medication,
          payment_plan_duration: info.payment_plan_duration,
          payment_plan_amount: info.payment_plan_amount,
          plan: info.plan,
          invoice_amount: info.invoice_amount
        }
      : {
          pb_package_name: '',
          medication: null,
          payment_plan_duration: null,
          payment_plan_amount: null,
          plan: '',
          invoice_amount: null
        }
  });

  const { mutateAsync: updatePackage, isPending: isUpdatePending } =
    useMutation({
      mutationFn: (data: PackagesFormInputs) =>
        handleUpdatePackage(data, info.id),
      onSuccess(data) {
        if (data !== undefined) {
          queryClient.invalidateQueries({ queryKey: ['packages'] });
          setIsClose();
        }
      }
    });

  const { mutateAsync: addPackage, isPending: isAddPending } = useMutation({
    mutationFn: (data: PackagesFormInputs) =>
      handleAddPackage(data, String(id)),
    onSuccess(data) {
      reset();
      if (data !== undefined) {
        queryClient.invalidateQueries({ queryKey: ['orders'] });
        setIsClose();
      }
    }
  });

  function getSelectedNames(data: Item[]): string | undefined {
    if (data) {
      return data
        .filter((item) => item.selected)
        .map((item) => item.name)
        .join(', ');
    }
  }

  const onSubmit = (data: any) => {
    const dirtyData: PackagesFormInputs = {};
    Object.keys(data).forEach((key) => {
      const objKey = key as keyof PackagesFormInputs;
      if (dirtyFields[objKey]) {
        dirtyData[objKey] = data[objKey];
      }
    });
    if (info) {
      updatePackage(dirtyData);
    } else {
      addPackage(dirtyData);
    }
  };

  const isPending = isUpdatePending || isAddPending;

  return (
    <>
      <p className='text-base font-semibold'>Package Detail</p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid grid-cols-2 mt-3.5 gap-6'>
          <div>
            <Label className='!font-normal'>Package Name</Label>
            <Controller
              name='pb_package_name'
              control={control}
              render={({ field }) =>
                info?.id ? (
                  <Select
                    className='w-full mt-2 h-[38px]'
                    {...field}
                    placeholder='Select'
                    data={info.pb_package_name}
                    labelKey='name'
                    valueKey='name'
                  />
                ) : (
                  <Input type='text' className='mt-2' {...field} />
                )
              }
            />
          </div>
          <div>
            <Label className='!font-normal'>Medication</Label>
            <Controller
              name='medication'
              control={control}
              render={({ field }) => (
                <Select
                  className='w-full mt-2 h-[38px]'
                  {...field}
                  placeholder='Select'
                  options={[
                    { value: 'Semaglutide', label: 'Semaglutide' },
                    { value: 'Tirzepatide', label: 'Tirzepatide' }
                  ]}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Invoice Amount ($)</Label>
            <Controller
              name='invoice_amount'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div className='mr-4'>
            <Label className='!font-normal mb-1.5'>Plan</Label>
            <Controller
              name='plan'
              control={control}
              render={({ field }) => (
                <Select
                  placeholder='Select Plan'
                  {...field}
                  className='w-full h-[30px]'
                  options={[
                    { value: 'Month to month', label: 'Month To month' },
                    { value: '3 month', label: '3 month' },
                    { value: '9 month', label: '9 month' }
                  ]}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>
              Payment Plan Duration (Months)
            </Label>
            <Controller
              name='payment_plan_duration'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
          <div>
            <Label className='!font-normal'>Payment Plan Amount ($)</Label>
            <Controller
              name='payment_plan_amount'
              control={control}
              render={({ field }) => (
                <Input
                  type='number'
                  className='mt-2'
                  {...field}
                  onChange={(event) => field.onChange(+event.target.value)}
                />
              )}
            />
          </div>
        </div>
        <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
          <Button className='!w-fit py-3 px-8' isLoading={isPending}>
            Submit
          </Button>
        </div>
      </form>
    </>
  );
}
