import React from 'react';
import './App.css';
import { RouterProvider } from 'react-router-dom';
import { routes } from './constants/routes';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10000,
      refetchOnMount: false,
      refetchOnWindowFocus: false
    }
  }
});

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={routes} fallbackElement={<>loading...</>} />
    </QueryClientProvider>
  );
}

export default App;
