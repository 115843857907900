import { ArrowLeftOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Spin } from 'antd';
import { queryClient } from 'App';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import { Label } from 'components/label/Label';
import Select from 'components/select/Select';
import Textarea from 'components/textarea/Textarea';
import paths from 'constants/path';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { request } from 'utils/api';

interface KnowledgeBaseFormInput {
  question?: string;
  answer?: string;
  category_id?: string;
}

const getCategories = async () => {
  const data = await request({
    url: '/categories',
    method: 'GET'
  });
  return data;
};

const addKnowledgeBase = async (payload: KnowledgeBaseFormInput) => {
  const response = await request({
    url: '/knowledge-bases',
    method: 'POST',
    data: payload
  });
  return response;
};

const editKnowledgeBase = async (
  payload: KnowledgeBaseFormInput,
  knowledge_base_id: string | undefined
) => {
  const response = await request({
    url: `/knowledge-bases/${knowledge_base_id}`,
    method: 'PUT',
    data: payload,
    params: { knowledge_base_id }
  });
  return response;
};

const getSingleKnowledgeBase = async (id: string | undefined) => {
  const data = await request({
    url: `/knowledge-bases/${id}`,
    method: 'GET',
    params: { knowledge_base_id: id }
  });
  return data;
};

export default function KnowledgeBaseForm() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { data: singleKnowledgeBase, isLoading: isKnowledgeBaseLoading } =
    useQuery({
      queryKey: ['singleKnowledgeBase', id],
      queryFn: () => getSingleKnowledgeBase(id),
      enabled: Boolean(id)
    });

  const {
    control,
    handleSubmit,
    formState: { dirtyFields }
  } = useForm({
    values: id
      ? {
          question: singleKnowledgeBase?.question,
          answer: singleKnowledgeBase?.answer,
          category_id: singleKnowledgeBase?.category
        }
      : {}
  });

  const { data: CategoryData } = useQuery({
    queryKey: ['categoryData'],
    queryFn: () => getCategories()
  });

  const { mutateAsync: AddKnowledgeBase, isPending: isAddingKnowledgeBase } =
    useMutation({
      mutationFn: (data: KnowledgeBaseFormInput) => addKnowledgeBase(data),
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ['orders'] });
        navigate(paths.knowledgeBase);
      }
    });

  const { mutateAsync: EditKnowledgeBase, isPending: isEditingKnowledgeBase } =
    useMutation({
      mutationFn: (data: KnowledgeBaseFormInput) => {
        const dirtyData: KnowledgeBaseFormInput = {};
        Object.keys(data).forEach((key) => {
          const objKey = key as keyof KnowledgeBaseFormInput;
          if (dirtyFields[objKey]) {
            dirtyData[objKey] = data[objKey];
          }
        });
        return editKnowledgeBase(dirtyData, id);
      },
      onSuccess() {
        queryClient.invalidateQueries({ queryKey: ['orders'] });
        navigate(paths.knowledgeBase);
      }
    });

  const onSubmit = (data: any) => {
    if (id) {
      EditKnowledgeBase(data);
    } else {
      AddKnowledgeBase(data);
    }
  };

  if (isKnowledgeBaseLoading) {
    return <Spin fullscreen />;
  }

  return (
    <div className='p-8 bg-[#FAFBFC]'>
      <div className='grid grid-cols-12'>
        <div className='col-start-3 col-span-8'>
          <Link to={paths.knowledgeBase}>
            <Button className='flex gap-2 items-center !w-fit px-3 bg-transparent border border-orange !text-orange'>
              <ArrowLeftOutlined />
              Back
            </Button>
          </Link>
          <div className='border border-light-grey rounded py-10 px-6 mt-6 bg-white'>
            <p className='font-bold'>{id ? 'Edit' : 'Create'} Prompt</p>
            <form className='mt-6' onSubmit={handleSubmit(onSubmit)}>
              <Label className='!font-normal text-sm'>Category</Label>
              <Controller
                name='category_id'
                control={control}
                render={({ field }) => (
                  <Select
                    placeholder='Select Category'
                    className='w-full mt-2 h-[38px]'
                    options={CategoryData?.map(
                      (data: { id: string; name: string }) => ({
                        value: data.id,
                        label: data.name
                      })
                    )}
                    {...field}
                  />
                )}
              />
              <Label className='!font-normal text-sm mt-6'>Questions*</Label>
              <Controller
                name='question'
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder='Question'
                    type='text'
                    className='mt-2'
                    {...field}
                  />
                )}
              />
              <Label className='!font-normal text-sm mt-6'>Answer*</Label>
              <Controller
                name='answer'
                control={control}
                render={({ field }) => (
                  <Textarea
                    placeholder='Answer'
                    type='text'
                    className='mt-2'
                    {...field}
                  />
                )}
              />
              <div className='flex gap-5 justify-end mt-6'>
                <Link to={paths.knowledgeBase}>
                  <button className='!w-[148px] py-2 px-6 text-sm !rounded bg-light-grey !text-silver'>
                    Cancel
                  </button>
                </Link>
                <Button
                  className='!w-[148px] px-6 text-sm !rounded'
                  isLoading={isAddingKnowledgeBase || isEditingKnowledgeBase}
                >
                  {id ? 'Update' : 'Add Prompt'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
