import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { ColumnDef, RowSelectionState } from '@tanstack/react-table';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import formatDate from 'utils/helpers/date';
import Drawer from 'components/drawer/Drawer';
import {
  EyeOutlined,
  FileAddOutlined,
  FolderAddOutlined,
  SearchOutlined
} from '@ant-design/icons';
import { InitialConsultationsResponse } from 'types/initialConsultations';
import InitialConsultationsForm from './components/InitialConsultationsForm';
import { Modal, Spin } from 'antd';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import Select from 'components/select/Select';
import TextArea from 'antd/es/input/TextArea';
import { Link } from 'react-router-dom';
import paths from 'constants/path';
import PopupConfirm from 'components/popupConfirm/PopupConfirm';
import Tooltip from 'components/tooltip/Tooltip';
import IndeterminateCheckbox from 'components/indeterminateCheckbox/IndeterminateCheckbox';
import { FaSort } from 'react-icons/fa';

interface InitialConsultationsFilter {
  form_id: string;
  name: string;
  status: string;
  consultant_id: string;
}

const getUsers = async () => {
  const data = await request({
    url: '/users',
    method: 'GET',
    params: { page: 1, per_page: 30 }
  });
  return data;
};

const getInitialConsultations = async (
  currentPage: number,
  params?: InitialConsultationsFilter,
  sortingDetails?: any
) => {
  const data = await request({
    url: '/form-requests',
    method: 'GET',
    params: { page: currentPage, ...params, ...sortingDetails }
  });
  return data;
};

const handleSendTaskMessage = async (
  payload: { task_message: string },
  Id: string
) => {
  const response = await request({
    url: `form-requests/send-task-email/${Id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

const handleArchiveData = async (ids: string[]) => {
  const response = await request({
    url: 'form-requests/status-update',
    method: 'PUT',
    data: { ids: [...ids] }
  });
  return response;
};

export default function InitialConsultations() {
  const [currentPage, setCurrentPage] = useState(1);
  const [initialConsultationFilters, setInitialConsultationFilters] = useState({
    form_id: '6729169fd97dc2e0e3624318',
    name: '',
    status: 'Pending',
    consultant_id: ''
  });
  const [sortingDetails, setSortingDetails] = useState({
    sort_by: 'created_at',
    sort_order: 'asc'
  });
  const [isMessageInputModalOpen, setIsMessageInputModalOpen] = useState(false);
  const [taskMessage, setTaskMessage] = useState('');
  const [drawerId, setDrawerId] = useState('');
  const [editingRecord, setEditingRecord] = useState(false);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const selectedRows = useMemo(() => Object.keys(rowSelection), [rowSelection]);

  const queryClient = useQueryClient();

  const { control, handleSubmit, reset } = useForm<InitialConsultationsFilter>({
    defaultValues: {}
  });

  const {
    data: InitialConsultationsData,
    isLoading,
    refetch
  } = useQuery({
    queryKey: [
      'initial_consultations',
      currentPage,
      initialConsultationFilters,
      sortingDetails
    ],
    queryFn: () =>
      getInitialConsultations(
        currentPage,
        initialConsultationFilters,
        sortingDetails
      )
  });

  // const {
  //   data: InitialConsultationsSecondData,
  //   isLoading: isSecondFormDataLoading,
  //   refetch: refetchSecondFormData
  // } = useQuery({
  //   queryKey: ['initial_consultations'],
  //   queryFn: () =>
  //     getInitialConsultations(currentPage, {
  //       ...initialConsultationFilters,
  //       form_id: '66ee0a46bf134186e8dd2616'
  //     })
  // });

  const { data: usersData } = useQuery({
    queryKey: ['usersData'],
    queryFn: () => getUsers()
  });

  const { mutateAsync: archiveData, isPending: isArchivePending } = useMutation(
    {
      mutationFn: (ids: string[]) => handleArchiveData(ids),
      onSuccess() {
        setRowSelection({});
        queryClient.invalidateQueries({
          queryKey: ['initial_consultations_archive']
        });
        queryClient.refetchQueries({ queryKey: ['initial_consultations'] });
      }
    }
  );

  const { mutateAsync: sendTaskMessageEmail, isPending: isSending } =
    useMutation({
      mutationFn: () =>
        handleSendTaskMessage(
          { task_message: taskMessage },
          String(editingRecord)
        ),
      onSuccess() {
        setTaskMessage('');
        setIsMessageInputModalOpen(false);
        setEditingRecord(false);
        queryClient.invalidateQueries({ queryKey: ['send_email'] });
        queryClient.refetchQueries({ queryKey: ['send_email'] });
      }
    });

  const handleCreateTaskClick = (id: any) => {
    setEditingRecord(id);
    setIsMessageInputModalOpen(true);
  };

  // const initialConsultationsAllData = useMemo(() => {
  //   return [
  //     ...(InitialConsultationsSecondData?.form_requests ?? []),
  //     ...(InitialConsultationsData?.form_requests ?? []),
  //   ];
  // }, [
  //   InitialConsultationsData?.form_requests,
  //   InitialConsultationsSecondData?.form_requests,
  // ]);

  const initialConsultationsMemorizedData = useMemo(() => {
    return InitialConsultationsData?.form_requests ?? [];
  }, [InitialConsultationsData?.form_requests]);

  const onSubmit: SubmitHandler<InitialConsultationsFilter> = (data) => {
    setInitialConsultationFilters(data);
    setCurrentPage(1);
  };

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold'>Initial Consultations</h1>
        <div className='flex justify-between items-center'>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='filters flex gap-6 items-center my-4'>
              <Controller
                name='name'
                control={control}
                render={({ field }) => (
                  <Input placeholder='Name' type='text' {...field} />
                )}
              />
              <Controller
                name='status'
                control={control}
                render={({ field }) => (
                  <Select
                    placeholder='Select Status'
                    defaultValue={initialConsultationFilters?.status}
                    {...field}
                    className='w-[150px] h-[38px]'
                    options={[
                      { label: 'Pending', value: 'Pending' },
                      { label: 'Reviewed', value: 'Reviewed' },
                      { label: 'Archive', value: 'Archive' },
                      { label: 'Follow-up', value: 'Follow-up' }
                    ]}
                  />
                )}
              />
              <Controller
                name='consultant_id'
                control={control}
                render={({ field }) => (
                  <Select
                    placeholder='Consultant'
                    options={usersData?.users
                      ?.filter(
                        (data: { pb_consultant_id: string }) =>
                          !!data.pb_consultant_id
                      )
                      .map(
                        (data: {
                          pb_consultant_id: string;
                          first_name: string;
                        }) => ({
                          value: data.pb_consultant_id,
                          label: data.first_name
                        })
                      )}
                    className='w-[150px] h-[38px]'
                    {...field}
                  />
                )}
              />
              <Button className='w-max px-3.5 h-[38px]'>
                <SearchOutlined className='!text-white' />
              </Button>
              <button
                onClick={() =>
                  reset({
                    form_id: '6729169fd97dc2e0e3624318',
                    name: '',
                    status: ''
                  })
                }
                className='border border-orange rounded py-2.5 px-3'
              >
                <MdOutlineFilterAltOff className='text-orange' />
              </button>
            </div>
          </form>
          <button
            disabled={!(selectedRows.length > 1) || isArchivePending}
            className='border border-1 border-orange px-2 py-1 bg-orange text-white rounded-lg w-32 disabled:opacity-50 disabled:cursor-not-allowed'
            onClick={() => archiveData([...selectedRows])}
          >
            {isArchivePending ? 'Archiving...' : 'Archive'}
          </button>
        </div>
        <div className='overflow-auto h-[calc(100vh_-_267px)]'>
          <DataTable
            isLoading={isLoading}
            data={initialConsultationsMemorizedData}
            setRowSelection={setRowSelection}
            rowSelection={rowSelection}
            columns={columns(
              handleCreateTaskClick,
              archiveData,
              drawerId,
              setDrawerId,
              refetch,
              setSortingDetails,
              sortingDetails
            )}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={InitialConsultationsData?.pagination.total_count}
        totalPage={InitialConsultationsData?.pagination.total_pages}
        perPage={InitialConsultationsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <Modal
        open={isMessageInputModalOpen}
        onCancel={() => setIsMessageInputModalOpen(false)}
        footer={[]}
      >
        <div className='px-5 py-2'>
          <h2 className='text-lg font-semibold text-orange mb-3'>
            Add Message
          </h2>
          <TextArea
            value={taskMessage}
            onChange={(e) => setTaskMessage(e.target.value)}
          />
        </div>
        <button
          disabled={!taskMessage}
          type='submit'
          className={`!w-fit py-3 px-8 bg-orange text-white py-2 rounded-lg font-semibold ml-40 ${!taskMessage && 'disabled:opacity-50 disabled:cursor-not-allowed'}`}
          onClick={() => sendTaskMessageEmail()}
        >
          {isSending ? <Spin /> : 'Submit'}
        </button>
      </Modal>
    </div>
  );
}

const columns = (
  handleCreateTaskClick: (id: string) => void,
  archiveData: (id: string[]) => void,
  drawerId: string,
  setDrawerId: any,
  refetch: any,
  setSortingDetails: any,
  sortingDetails: any
): ColumnDef<InitialConsultationsResponse>[] => [
  {
    id: 'select',
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllRowsSelected(),
          indeterminate: table.getIsSomeRowsSelected(),
          onChange: table.getToggleAllRowsSelectedHandler()
        }}
      />
    ),
    cell: ({ row }) => (
      <div className='px-1'>
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler()
          }}
        />
      </div>
    )
  },
  {
    header: 'Name',
    accessorKey: 'name',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/clients/${info.row.original.id}`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {info.getValue<string>()}
        </p>
      </a>
    )
  },
  {
    header: 'Email',
    accessorKey: 'email',
    cell: (info) => (
      <Link
        to={paths.customer(String(info.row.original.client_record_id))}
        className='text-orange underline underline-offset-4'
      >
        {info.getValue<string>()}
      </Link>
    )
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Date Submitted</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'due_date',
              sort_order:
                sortingDetails?.sort_by === 'completed_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'completed_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Service',
    accessorKey: 'service',
    cell: (info) =>
      info.getValue() ? (
        <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center capitalize w-20'>
          {info.getValue() as string}
        </p>
      ) : (
        '---'
      )
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Preferred Medication</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'preferred_medications',
              sort_order:
                sortingDetails?.sort_by === 'preferred_medications' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'preferred_medications',
    cell: (info) =>
      info.getValue() ? (
        <p className='border border-blue rounded-full py-1 px-2 text-xs text-blue bg-light-blue text-center capitalize'>
          {info.getValue() as string}
        </p>
      ) : (
        '---'
      )
  },
  {
    header: 'Preferred Plan',
    accessorKey: 'preferred_plan',
    cell: (info) => (
      <span>
        <Tooltip title={String(info.getValue())}>
          {String(info.getValue()).length < 30
            ? String(info.getValue())
            : `${String(info.getValue()).slice(0, 30)}...`}
        </Tooltip>
      </span>
    )
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Draft Date</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'created_at',
              sort_order:
                sortingDetails?.sort_by === 'created_at' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Consultant',
    accessorKey: 'consultant'
  },
  {
    header: () => (
      <div className='flex items-center'>
        <span className='px-1 text-nowrap'>Status</span>
        <FaSort
          className='cursor-pointer'
          onClick={() =>
            setSortingDetails({
              sort_by: 'status',
              sort_order:
                sortingDetails?.sort_by === 'status' &&
                sortingDetails?.sort_order === 'desc'
                  ? 'asc'
                  : 'desc'
            })
          }
        />
      </div>
    ),
    accessorKey: 'status',
    cell: (info) => (
      <span className='text-neutral-700'>{info.getValue() as string}</span>
    )
  },
  {
    header: 'Action',
    accessorKey: 'id',
    cell: (info) => (
      <div className='flex items-center gap-3 text-base'>
        <button
          className='text-orange cursor-pointer whitespace-nowrap font-medium mr-0.5'
          onClick={() => handleCreateTaskClick(String(info.getValue()))}
        >
          <Tooltip title='Send email.'>
            <FileAddOutlined className='cursor-pointer' />
          </Tooltip>
        </button>
        <Drawer
          styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
          width='90%'
          button={
            <Tooltip title='Review details.'>
              <EyeOutlined
                className='cursor-pointer'
                onClick={() => setDrawerId(info.getValue())}
              />
            </Tooltip>
          }
          closeIcon={null}
          open={drawerId === info.getValue()}
        >
          <InitialConsultationsForm
            info={info.row.original}
            setDrawerId={setDrawerId}
            refetchFormRequests={refetch}
          />
        </Drawer>
        {info.row.original.status !== 'Archive' && (
          <PopupConfirm
            title='Archive Record'
            description='Are you sure you want to archive?'
            onConfirm={() => archiveData([String(info.getValue())])}
          >
            <Tooltip title='Move to archives.'>
              <FolderAddOutlined className='text-orange cursor-pointer' />
            </Tooltip>
          </PopupConfirm>
        )}
      </div>
    )
  }
];
