import React, { forwardRef } from 'react';

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  className?: string;
}
const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { id, className, ...props },
  ref
) {
  return (
    <input
      type='checkbox'
      id={id}
      className={`w-6 h-6 accent-orange ${className}`}
      ref={ref}
      {...props}
    />
  );
});

export default Checkbox;
