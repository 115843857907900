import {
  ArrowLeftOutlined,
  MailOutlined,
  PhoneOutlined
} from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { Avatar } from 'antd';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Link, useParams } from 'react-router-dom';
import { Customer } from 'types/customer';
import { request } from 'utils/api';
import Orders from './components/Orders';
import CheckIns from './components/CheckIns';
import { formatAddress } from 'utils/helpers/address';

const getCustomer = async (id: string | undefined) => {
  const data = await request({
    url: `/clients/${id}`,
    method: 'GET'
  });
  return data;
};

export default function CustomerPage() {
  const { id } = useParams();

  const { data: customerData } = useQuery<Customer>({
    queryKey: ['customer', id],
    queryFn: () => getCustomer(id)
  });
  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='grid grid-cols-10 gap-4'>
        <div className='col-span-2 bg-white rounded-xl border border-light-grey'>
          <div className='bg-light-orange rounded-xl py-4 px-3'>
            <div className='flex justify-between'>
              <Link to='/customers'>
                <ArrowLeftOutlined className='text-orange bg-light-orange p-2 rounded-full' />
              </Link>
              <div className='bg-light-orange p-2 rounded-full'>
                <BsThreeDotsVertical className='text-orange ' />
              </div>
            </div>
            <Avatar
              className='w-20 h-20 block mx-auto'
              src='https://api.dicebear.com/7.x/miniavs/svg?seed=1'
            />
            <p className='text-xl text-center font-semibold mt-4'>
              {`${customerData?.first_name} ${customerData?.last_name}`}
            </p>
          </div>
          <div className='p-5'>
            <div className='flex gap-2 items-center'>
              <MailOutlined className='text-silver' />
              <span className='text-sm'>{customerData?.email}</span>
            </div>
            <div className='flex gap-2 items-center mt-4'>
              <PhoneOutlined className='w-4 h-4 text-silver' rotate={90} />
              <span className='text-sm'>{customerData?.mobile_phone}</span>
            </div>
          </div>
          <div className='border-t border-light-grey mx-5' />
          <div className='p-5'>
            <p className='text-sm text-silver'>Patient ID</p>
            <p className='font-semibold text-sm'>{customerData?.id}</p>
            <div className='flex flex-col mt-3'>
              {customerData?.ghl && (
                <Link
                  to={String(customerData?.ghl)}
                  className='text-orange underline underline-offset-4 text-sm'
                  target='_blank'
                >
                  GHL
                </Link>
              )}
              <Link
                to={`https://my.practicebetter.io/#/p/clients/${customerData?.id}`}
                className='text-orange underline underline-offset-4 text-sm mt-3'
                target='_blank'
              >
                P B
              </Link>
            </div>
            <p className='text-sm text-silver mt-4'>Age</p>
            <p className='font-semibold text-sm'>{customerData?.age}</p>
            <p className='text-sm text-silver mt-4'>Gender</p>
            <p className='font-semibold text-sm'>
              {customerData?.profile.genderIdentity === 'Man/Boy'
                ? 'Male'
                : 'Female'}
            </p>
            <p className='text-sm text-silver mt-4'>Location</p>
            <p className='font-semibold text-sm'>
              {formatAddress(customerData?.profile.address)}
            </p>
          </div>
        </div>
        <div className='col-span-8'>
          <Orders />
          <div className='pt-2'>
            <CheckIns />
          </div>
        </div>
      </div>
    </div>
  );
}
