import React from 'react';

interface LabelProps {
  htmlFor?: string;
  className?: string;
  children: React.ReactNode;
}

export const Label = ({ htmlFor, className, children }: LabelProps) => {
  return (
    <label className={`block font-semibold ${className}`} htmlFor={htmlFor}>
      {children}
    </label>
  );
};
