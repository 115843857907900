import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import paths from 'constants/path';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import formatDate from 'utils/helpers/date';
import { SmsLogsResponse } from 'types/smsLogs';

const getSmsLogs = async (currentPage: number) => {
  const data = await request({
    url: '/sms-logs',
    method: 'GET',
    params: { page: currentPage }
  });
  return data;
};

export default function SmsLogsPage() {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: smsLogsData, isLoading } = useQuery({
    queryKey: ['sms-logs', currentPage],
    queryFn: () => getSmsLogs(currentPage)
  });

  const smsLogs = useMemo(() => {
    return smsLogsData?.sms_logs ?? [];
  }, [smsLogsData?.sms_logs]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold mb-2'>Sms Logs</h1>
        <div className='overflow-auto h-[calc(100vh_-_250px)]'>
          <DataTable isLoading={isLoading} data={smsLogs} columns={columns} />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={smsLogsData?.pagination.total_count}
        totalPage={smsLogsData?.pagination.total_pages}
        perPage={smsLogsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

const columns: ColumnDef<SmsLogsResponse>[] = [
  {
    header: 'To',
    accessorKey: 'to'
  },
  {
    header: 'From',
    accessorKey: 'from'
  },
  {
    header: 'Message',
    accessorKey: 'message'
  },
  {
    header: 'Status',
    accessorKey: 'status'
  },
  {
    header: 'Sms sid',
    accessorKey: 'sms_sid'
  },
  {
    header: 'Created At',
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: 'Updated At',
    accessorKey: 'updated_at',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: 'Action',
    accessorKey: '',
    cell: (info) => (
      <div className='flex flex-row gap-2'>
        <Link
          to={paths.customer(info.row.original.client_record_id)}
          className='text-orange underline underline-offset-4'
        >
          Customer
        </Link>
        <Link
          to={info.row.original.log_url}
          className='text-orange underline underline-offset-4'
          target='_blank'
        >
          Twilio
        </Link>
      </div>
    )
  }
];
