import React from 'react';
import { Navigate } from 'react-router-dom';
import { getCookie } from 'utils/cookies';

export default function PrivateRoute({
  children
}: {
  children: React.ReactNode;
}) {
  if (getCookie('token')) {
    return <>{children}</>;
  } else {
    return <Navigate to='/login' replace />;
  }
}
