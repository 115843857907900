import {
  ArrowRightOutlined,
  InfoCircleOutlined,
  RedoOutlined
} from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Input, Spin } from 'antd';
import { Label } from 'components/label/Label';
import Radio from 'components/radio/Radio';
import Select from 'components/select/Select';
import Tooltip from 'components/tooltip/Tooltip';
import WysiwygEditor from 'components/wysiwygEditor/WysiwygEditor';
import {
  semaglutideDoseOptions,
  tirzepatideDoseOptions
} from 'constants/doseOptions';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { InitialConsultationsResponse } from 'types/initialConsultations';
import { request } from 'utils/api';
import formatDate from 'utils/helpers/date';

interface InitialConsultationFormInputs {
  completed_at?: string;
  message_to_patient?: string;
  medication?: string;
  name?: string;
  preferred_medications?: string;
  preferred_plan?: string;
  submit?: boolean | string;
  weight?: string | number;
  dose?: number | string;
  id?: number | string | null;
  ic_assessment_and_plan?: string;
  ic_hpi?: string;
  ic_pmh?: string;
  status?: string;
  discount?: string;
  send_review?: boolean | string;
  plan?: string;
  goals?: string;
}

interface SessionNotesPayLoad {
  status?: string;
}

type Item = {
  name: string;
  selected: boolean;
};

const getDropdownValues = async (id: string) => {
  const data = await request({
    url: `/form-requests/drop-downs/${id}`,
    method: 'GET'
  });
  return data;
};

const handleUpdateInitialConsultations = async (
  payload: InitialConsultationFormInputs,
  Id: string
) => {
  const response = await request({
    url: `form-requests/${Id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

const handleSessionNotes = async (payload: SessionNotesPayLoad, Id: string) => {
  const response = await request({
    url: `/form-requests/make-session-note/${Id}`,
    method: 'PUT',
    data: payload
  });
  return response;
};

const handleRefreshAIPrompts = async (columnName: string, id: string) => {
  const response = await request({
    url: `form-requests/refresh-ai-prompts/${id}`,
    method: 'PUT',
    data: { column_name: columnName }
  });
  return response;
};

const handleCreateInitialConsultations = async (
  payload: InitialConsultationFormInputs
) => {
  const response = await request({
    url: 'form-requests',
    method: 'POST',
    data: payload
  });
  return response;
};

export default function InitialConsultationsForm({
  info,
  setDrawerId,
  refetchFormRequests
}: {
  info?: InitialConsultationsResponse;
  setDrawerId: any;
  refetchFormRequests: any;
}) {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const [updatedFormData, setUpdatedFormData] =
    useState<InitialConsultationsResponse | null>();
  const [refreshingColumn, setRefreshingColumn] = useState('');
  const [followUpClicked, setFollowUpClicked] = useState(false);
  let currentPackage = updatedFormData?.id
    ? updatedFormData.package
    : info?.package;

  const { data: dropdownValues } = useQuery({
    queryKey: ['dropdown_values', updatedFormData],
    queryFn: () => getDropdownValues(String(info?.id))
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { dirtyFields, errors }
  } = useForm<InitialConsultationFormInputs>({
    values: updatedFormData?.id
      ? {
          completed_at: formatDate(updatedFormData.completed_at),
          medication: updatedFormData.medication,
          name: updatedFormData.name,
          preferred_medications: updatedFormData.preferred_medications,
          preferred_plan: getSelectedNames(dropdownValues?.preffered_plan),
          submit: updatedFormData.submit,
          weight: updatedFormData.weight,
          dose: updatedFormData.dose,
          id: updatedFormData.id,
          message_to_patient: updatedFormData.message_to_patient,
          ic_assessment_and_plan: updatedFormData.ic_assessment_and_plan,
          ic_hpi: updatedFormData.ic_hpi,
          ic_pmh: updatedFormData.ic_pmh,
          discount: getSelectedNames(dropdownValues?.discount),
          send_review: updatedFormData.send_review,
          plan: updatedFormData.plan,
          goals: updatedFormData.goals
        }
      : info
        ? {
            completed_at: formatDate(info.completed_at),
            medication: info.medication,
            name: info.name,
            preferred_medications: info.preferred_medications,
            preferred_plan: getSelectedNames(dropdownValues?.preffered_plan),
            submit: info.submit,
            weight: info.weight,
            dose: info.dose,
            id: info.id,
            message_to_patient: info.message_to_patient,
            ic_assessment_and_plan: info.ic_assessment_and_plan,
            ic_hpi: info.ic_hpi,
            ic_pmh: info.ic_pmh,
            discount: getSelectedNames(dropdownValues?.discount),
            send_review: info.send_review,
            plan: info.plan,
            goals: info.goals
          }
        : {
            completed_at: '',
            medication: '',
            name: '',
            preferred_medications: '',
            preferred_plan: '',
            submit: false,
            weight: '',
            dose: '',
            id: null,
            message_to_patient: '',
            ic_assessment_and_plan: '',
            ic_hpi: '',
            ic_pmh: '',
            discount: '',
            send_review: false,
            plan: '',
            goals: ''
          }
  });
  const selectedMedication = watch('medication');
  const {
    mutateAsync: updateInitialConsultations,
    isPending: isUpdatePending
  } = useMutation({
    mutationFn: (data: InitialConsultationFormInputs) =>
      handleUpdateInitialConsultations(data, String(info?.id)),
    onSuccess(data) {
      if (data !== undefined) {
        setUpdatedFormData(data.form_request);
      }
    }
  });

  const { mutateAsync: createSessionNotes, isPending: isCreationPending } =
    useMutation({
      mutationFn: (data: SessionNotesPayLoad) =>
        handleSessionNotes(data, String(info?.id)),
      onSuccess(data) {
        if (data !== undefined) {
          setUpdatedFormData(data.form_request);
        }
      }
    });

  const { mutateAsync: refreshAIPrompts, isPending: isRefreshPending } =
    useMutation({
      mutationFn: (columnName: string) =>
        handleRefreshAIPrompts(columnName, String(info?.id)),
      onSuccess(data) {
        if (data !== undefined) {
          setUpdatedFormData(data.form_request);
        }
      }
    });

  const {
    mutateAsync: createInitialConsultations,
    isPending: isCreatePending
  } = useMutation({
    mutationFn: (data: InitialConsultationFormInputs) =>
      handleCreateInitialConsultations(data),
    onSuccess(data) {
      if (data !== undefined) {
        reset();
        queryClient.invalidateQueries({
          queryKey: ['initial_consultations']
        });
      }
    }
  });

  const onSubmit = async (data: InitialConsultationFormInputs) => {
    let dirtyData: Partial<InitialConsultationFormInputs> = {};
    data = followUpClicked
      ? { ...data, status: 'Follow-up' }
      : { ...data, status: 'Reviewed' };

    Object.keys(data).forEach((key) => {
      const objKey = key as keyof InitialConsultationFormInputs;

      if (dirtyFields[objKey]) {
        const value = data[objKey];
        if (typeof value === 'string' || value === undefined) {
          dirtyData[objKey] = value;
        } else if (value !== null) {
          dirtyData[objKey] = String(value);
        }
      }
    });
    if (info) {
      dirtyData = followUpClicked
        ? { ...dirtyData, status: 'Follow-up' }
        : { ...dirtyData, status: 'Reviewed' };
      await updateInitialConsultations(dirtyData);
      createSessionNotes({ status: dirtyData.status });
    } else {
      createInitialConsultations({ ...dirtyData, id: id });
    }
  };

  const isPending = isUpdatePending || isCreatePending;
  const isFormLocked =
    updatedFormData?.status === 'Reviewed' || info?.status === 'Reviewed';

  function getSelectedNames(data: Item[]): string | undefined {
    if (data) {
      return data
        .filter((item) => item.selected)
        .map((item) => item.name)
        .join(', ');
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-row'>
        <div className='w-1/2 border-r'>
          <p className='text-base font-semibold border-b mr-9 pb-2'>
            Patient Name - {info?.name}
          </p>
          <div
            className={`m-3 ml-0 ${isFormLocked && 'pointer-events-none opacity-50'}`}
          >
            <div className='mb-6'>
              <Label className='!font-normal mb-1.5'>
                <span className='flex flex-row'>
                  <span>HPI</span>
                  {isRefreshPending && refreshingColumn === 'ic_hpi' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('ic_hpi');
                        setRefreshingColumn('ic_hpi');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='ic_hpi'
                control={control}
                render={({ field }) => <WysiwygEditor {...field} />}
              />
            </div>
            <div className='mb-6'>
              <Label className='!font-normal mb-1.5'>
                <span className='flex flex-row'>
                  <span>PMH</span>
                  {isRefreshPending && refreshingColumn === 'ic_pmh' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('ic_pmh');
                        setRefreshingColumn('ic_pmh');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='ic_pmh'
                control={control}
                render={({ field }) => <WysiwygEditor {...field} />}
              />
            </div>
          </div>
        </div>
        <div className='w-1/2 pl-4'>
          <div className='border-b flex flex-row justify-between'>
            <span className='text-base font-semibold pb-2'>
              {isFormLocked ? (
                <>
                  Details
                  <Tooltip title='Form status is "Reviewed". So, no further actions can be performed.'>
                    <InfoCircleOutlined className='ml-1 text-sm' />
                  </Tooltip>
                </>
              ) : (
                'Edit Details'
              )}
            </span>
            <ArrowRightOutlined
              onClick={() => {
                setDrawerId('');
                setUpdatedFormData(null);
                refetchFormRequests();
              }}
              className='text-lg pr-4 pb-3'
            />
          </div>
          <div
            className={`mt-3.5 gap-6 mb-20 ${isFormLocked && 'pointer-events-none opacity-50'}`}
          >
            <div className='grid grid-cols-3 mb-7'>
              <div className='mr-6'>
                <Label className='!font-normal'>Preferred Medication</Label>
                <Controller
                  disabled
                  name='preferred_medications'
                  control={control}
                  render={({ field }) => (
                    <Input
                      type='text'
                      className=' mt-2 w-full h-[30px]'
                      {...field}
                    />
                  )}
                />
              </div>
              <div className='mr-4'>
                <Label className='!font-normal mb-1.5'>Plan</Label>
                <Controller
                  name='preferred_plan'
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder='Select Plan'
                      {...field}
                      className='w-full h-[30px]'
                      options={[
                        { value: 'Month to month', label: 'Month To Month' },
                        { value: '3 month', label: '3 Months' },
                        { value: '9 month', label: '9 Months' }
                      ]}
                      onChange={(val) => {
                        updateInitialConsultations({ preferred_plan: val });
                      }}
                    />
                  )}
                />
              </div>
              <div className='mx-4'>
                <Label className='!font-normal mb-1.5'>Goals</Label>
                <Controller
                  name='goals'
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder='Select Goal'
                      {...field}
                      className='w-full h-[30px]'
                      options={[
                        { value: 'Maintenance', label: 'Maintenance' },
                        { value: 'Escalation', label: 'Escalation' },
                        { value: 'No preference', label: 'No preference' }
                      ]}
                      onChange={(val) => {
                        updateInitialConsultations({ goals: val });
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div>
              <div className='mt-3.5'>
                <Label className='!font-normal mb-1.5'>Discount</Label>
                <Controller
                  name='discount'
                  control={control}
                  render={({ field }) => (
                    <Select
                      placeholder='Select Discount'
                      {...field}
                      className='w-full h-[30px]'
                      data={dropdownValues?.discount}
                      labelKey='name'
                      valueKey='name'
                      onChange={(val) => {
                        updateInitialConsultations({ discount: val });
                      }}
                    />
                  )}
                />
              </div>
            </div>
            {currentPackage && (
              <div>
                <div className='border border-1 rounded p-2 my-3 mx-5 bg-light-orange'>
                  <div className='font-medium border-b m-2 pb-1 text-xs'>
                    Package ({getSelectedNames(currentPackage.pb_package_name)})
                  </div>
                  <div className='flex flex-row text-xs'>
                    <div className='flex flex-col w-1/2 border-r mr-3'>
                      <span className='flex flex-row'>
                        <span className='font-medium'>Invoice Amount:</span>
                        <span className='ml-1'>
                          ${currentPackage.invoice_amount}
                        </span>
                      </span>
                      <span className='flex flex-row'>
                        <span className='font-medium'>Discount Tag:</span>
                        <span className='ml-1'>
                          {currentPackage.discount_tag}
                        </span>
                      </span>
                    </div>
                    <div className='flex flex-col w-1/2'>
                      <span className='flex flex-row'>
                        <span className='font-medium'>
                          Payment Plan Amount:
                        </span>
                        <span className='ml-1'>
                          ${currentPackage.payment_plan_amount}
                        </span>
                      </span>
                      <span className='flex flex-row'>
                        <span className='font-medium'>
                          Payment Plan Duration:
                        </span>
                        <span className='ml-1'>
                          {currentPackage.payment_plan_duration}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className='grid grid-cols-3 mb-6 mt-3.5'>
              <div>
                <Label className='!font-normal'>Order Medication</Label>
                <Controller
                  name='medication'
                  control={control}
                  render={({ field }) => (
                    <Radio
                      className='w-full mt-2 h-[38px]'
                      {...field}
                      options={[
                        { value: 'Semaglutide', label: 'Semaglutide' },
                        { value: 'Tirzepatide', label: 'Tirzepatide' },
                        { value: 'No Order', label: 'No Order' }
                      ]}
                      onChange={(e) => {
                        updateInitialConsultations({
                          medication: e?.target?.value
                        });
                      }}
                    />
                  )}
                />
                <p className='text-red text-sm'>
                  {errors?.medication?.message}
                </p>
              </div>
              {selectedMedication !== 'No Order' && (
                <div className='w-[25rem]'>
                  <Label className='!font-normal'>Dose</Label>
                  <Controller
                    name='dose'
                    control={control}
                    render={({ field }) => (
                      <Radio
                        className='w-full mt-2 h-[38px]'
                        {...field}
                        options={
                          selectedMedication === 'Semaglutide'
                            ? semaglutideDoseOptions
                            : tirzepatideDoseOptions
                        }
                        onChange={(e) => {
                          updateInitialConsultations({
                            dose: e?.target?.value
                          });
                        }}
                      />
                    )}
                  />
                </div>
              )}
            </div>
            <div className='grid grid-cols-3 mb-6 mt-3.5'>
              <div>
                <Label className='!font-normal'>Send Review</Label>
                <Controller
                  name='send_review'
                  control={control}
                  render={({ field }) => (
                    <Radio
                      className='w-full mt-2 h-[38px]'
                      {...field}
                      options={[
                        { value: true, label: 'Yes' },
                        { value: false, label: 'No' }
                      ]}
                      onChange={(e) => {
                        updateInitialConsultations({
                          send_review: e?.target?.value
                        });
                      }}
                    />
                  )}
                />
                <p className='text-red text-sm'>
                  {errors?.medication?.message}
                </p>
              </div>
            </div>
            <div className='mb-6'>
              <Label className='!font-normal mb-1.5'>
                <span className='flex flex-row'>
                  <span>Message to Patient</span>
                  {isRefreshPending &&
                  refreshingColumn === 'message_to_patient' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('message_to_patient');
                        setRefreshingColumn('message_to_patient');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='message_to_patient'
                control={control}
                render={({ field }) => <WysiwygEditor {...field} />}
              />
            </div>
            <div>
              <Label className='!font-normal mb-1.5'>
                <span className='flex flex-row'>
                  <span>Assessment & Plan</span>
                  {isRefreshPending &&
                  refreshingColumn === 'ic_assessment_and_plan' ? (
                    <span className='text-orange px-2 text-sm mt-[3px]'>
                      Refreshing...
                    </span>
                  ) : (
                    <RedoOutlined
                      className='text-orange px-1 rotate-180 cursor-pointer mt-[3px]'
                      onClick={() => {
                        refreshAIPrompts('ic_assessment_and_plan');
                        setRefreshingColumn('ic_assessment_and_plan');
                      }}
                    />
                  )}
                </span>
              </Label>
              <Controller
                name='ic_assessment_and_plan'
                control={control}
                render={({ field }) => <WysiwygEditor {...field} />}
              />
            </div>
          </div>
          {!isFormLocked && (
            <div className='flex gap-5 bg-white justify-end absolute bottom-0 right-0 px-4 py-3 border-t w-full'>
              <button
                type='button'
                className='!w-fit py-3 px-8 bg-grey text-white py-2 rounded-lg font-semibold'
                onClick={() => {
                  setDrawerId('');
                  setUpdatedFormData(null);
                  refetchFormRequests();
                }}
              >
                Cancel
              </button>
              <button
                disabled={isCreationPending || isPending}
                type='submit'
                className='!w-fit py-3 px-8 bg-yellow-500 text-white py-2 rounded-lg font-semibold disabled:cursor-not-allowed'
                onClick={() => setFollowUpClicked(true)}
              >
                {followUpClicked && isCreationPending ? <Spin /> : 'Follow-up'}
              </button>
              {currentPackage && (
                <button
                  disabled={isCreationPending || isPending}
                  type='submit'
                  className='!w-fit py-3 px-8 bg-orange text-white py-2 rounded-lg font-semibold disabled:cursor-not-allowed w-36'
                  onClick={() => setFollowUpClicked(false)}
                >
                  {!followUpClicked && (isCreationPending || isPending) ? (
                    <Spin />
                  ) : (
                    'Submit'
                  )}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </form>
  );
}
