import moment from 'moment';

export default function formatDate(
  date: string | number | Date,
  format: string = 'MM/DD/YYYY'
) {
  if (!date) {
    return 'Not Available';
  }
  return moment(date).format(format);
}
