import {
  Drawer as DrawerComponent,
  DrawerProps as DrawerComponentProps
} from 'antd';
import React, { useState } from 'react';

interface DrawerProps extends DrawerComponentProps {
  children: React.ReactNode;
  button: React.ReactNode;
  width: string;
  title?: string;
}

export default function Drawer({
  children,
  button,
  title,
  width = '50%',
  ...props
}: DrawerProps) {
  const [isOpen, setIsOpen] = useState(false);

  const showDrawer = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      <button
        className='text-orange flex items-center gap-1'
        onClick={showDrawer}
      >
        {button}
      </button>
      <DrawerComponent
        title={title}
        onClose={onClose}
        open={isOpen}
        width={width}
        {...props}
      >
        {React.cloneElement(children as React.ReactElement, {
          setIsClose: onClose
        })}
      </DrawerComponent>
    </>
  );
}
