import { SearchOutlined } from '@ant-design/icons';
import React, { forwardRef } from 'react';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type: string;
  placeholder?: string;
  className?: string;
}

const Search = forwardRef<HTMLInputElement, InputProps>(function Search(
  { type, placeholder, className, ...props },
  ref
) {
  return (
    <div className='relative'>
      <SearchOutlined className='absolute left-2 top-2.5 text-silver' />
      <input
        type={type}
        className={`py-3 px-8 h-[35px] border rounded-lg focus:outline-none focus:border-orange ${className}`}
        placeholder={placeholder}
        ref={ref}
        {...props}
      />
    </div>
  );
});

export default Search;
