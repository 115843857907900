const paths = {
  profile: '/profile',
  customers: '/customers',
  orders: '/orders',
  checkins: '/checkins',
  customer: (id = ':id') => `/customers/${id}`,
  chatbot: '/chatbot',
  knowledgeBase: '/chatbot/knowledge-base',
  addKnowledgeBase: '/chatbot/add-knowledge-base',
  editKnowledgeBase: (id = ':id') => `/chatbot/knowledge-base/${id}`,
  intents: '/chatbot/intents',
  retrievalTesting: '/chatbot/retrieval-testing',
  knowledgeBaseApprovals: '/chatbot/knowledge-base-approvals',
  aiPrompts: '/ai-prompts',
  editAiPrompts: (id = ':id') => `/ai-prompts/${id}`,
  formRequest: '/form-request',
  settings: '/settings',
  smsLogs: '/settings/sms-logs',
  emailLogs: '/settings/email-logs',
  packages: '/settings/packages',
  initialConsultations: '/initial-consultations',
  virtualFollowups: '/virtual-followups',
  invoice: '/invoice',
  tasks: '/tasks'
};

export default paths;
