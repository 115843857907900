import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { ColumnDef } from '@tanstack/react-table';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import { PackagesResponse } from 'types/packages';
import Drawer from 'components/drawer/Drawer';
import {
  ArrowRightOutlined,
  EditOutlined,
  PlusOutlined
} from '@ant-design/icons';
import PackageForm from './components/PackageForm';
import Button from 'components/button/Button';

type Item = {
  name: string;
  selected: boolean;
};

const getPackages = async (currentPage: number) => {
  const data = await request({
    url: '/packages',
    method: 'GET',
    params: { page: currentPage }
  });
  return data;
};

export default function Packages() {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: packagesData, isLoading } = useQuery({
    queryKey: ['packages', currentPage],
    queryFn: () => getPackages(currentPage)
  });

  const packages = useMemo(() => {
    return packagesData?.packages ?? [];
  }, [packagesData?.packages]);

  function getSelectedNames(data: Item[]): string | undefined {
    if (data) {
      return data
        .filter((item) => item.selected)
        .map((item) => item.name)
        .join(', ');
    }
  }

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='flex justify-end mb-3'>
        <Drawer
          styles={{
            header: { display: 'flex', flexDirection: 'row-reverse' }
          }}
          width='50%'
          button={
            <Button className='!w-fit px-4 py-2.5 text-xs'>
              <PlusOutlined className='mr-2' />
              Add New Package
            </Button>
          }
          title='Edit Package'
          closeIcon={<ArrowRightOutlined />}
        >
          <PackageForm />
        </Drawer>
      </div>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold mb-2'>Packages</h1>
        <div className='overflow-auto h-[calc(100vh_-_300px)]'>
          <DataTable
            isLoading={isLoading}
            data={packages}
            columns={columns(getSelectedNames)}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={packagesData?.pagination.total_count}
        totalPage={packagesData?.pagination.total_pages}
        perPage={packagesData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

const columns = (
  getSelectedNames: (data: any) => string | undefined
): ColumnDef<PackagesResponse>[] => [
  {
    header: 'Name',
    accessorKey: 'pb_package_name',
    cell: (info) => <span>{String(getSelectedNames(info.getValue()))}</span>
  },
  {
    header: 'PB Id',
    accessorKey: 'pb_package_id',
    cell: (info) => (
      <a
        href={`https://my.practicebetter.io/#/p/packages/${info.row.original.pb_package_id}/edit`}
        rel='noopener noreferrer'
        target='_blank'
        className='flex items-center gap-2'
      >
        <p className='underline text-orange underline-offset-4'>
          {info.getValue<string>()}
        </p>
      </a>
    )
  },
  {
    header: 'Medication',
    accessorKey: 'medication'
  },
  {
    header: 'Invoice Amount',
    accessorKey: 'invoice_amount',
    cell: (info) => <span>${info.getValue<string>()}</span>
  },
  {
    header: 'Plan',
    accessorKey: 'plan'
  },
  {
    header: 'Payment Plan Duration',
    accessorKey: 'payment_plan_duration',
    cell: (info) => <span>{info.getValue<string>()} Months</span>
  },
  {
    header: 'Payment Plan Amount',
    accessorKey: 'payment_plan_amount',
    cell: (info) => <span>${info.getValue<string>()}</span>
  },
  {
    header: 'Discount',
    accessorKey: 'discount'
  },
  {
    header: 'Discount Tag',
    accessorKey: 'discount_tag'
  },
  {
    header: 'Action',
    accessorKey: '',
    cell: (info) => (
      <div className='flex flex-row gap-2'>
        <Drawer
          styles={{
            header: { display: 'flex', flexDirection: 'row-reverse' }
          }}
          width='50%'
          button={
            <>
              <EditOutlined className='mr-1' />
              Edit
            </>
          }
          title='Edit Package'
          closeIcon={<ArrowRightOutlined />}
        >
          <PackageForm info={info.row.original} />
        </Drawer>
      </div>
    )
  }
];
