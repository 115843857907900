import { Typography } from 'antd';
import { EmailLogsResponse } from 'types/emailLogs';

export default function EmailBodyView({
  info,
  setIsClose
}: {
  info: EmailLogsResponse;
  setIsClose: any;
}) {
  return (
    <div className='border rounded-lg p-3 bg-light-grey text'>
      <Typography.Paragraph>
        <span dangerouslySetInnerHTML={{ __html: info?.body }} />
      </Typography.Paragraph>
    </div>
  );
}
