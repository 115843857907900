import React from 'react';
import { Spin } from 'antd';

interface ButtonProps {
  type?: 'button' | 'submit' | 'reset' | undefined;
  className?: string;
  isLoading?: Boolean;
  children: React.ReactNode;
}
export default function Button({
  type = 'submit',
  className,
  isLoading,
  children
}: ButtonProps) {
  return (
    <button
      type={type}
      className={`w-full bg-orange text-white py-2 rounded-lg font-semibold ${className}`}
    >
      {isLoading ? <Spin /> : children}
    </button>
  );
}
