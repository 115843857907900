import { Address } from 'types/customer';

export const formatAddress = (address: Address | undefined) => {
  if (!address) return 'Address not available';

  const { unit, street, region, postalCode, country } = address;

  const formattedAddress = [unit, street, region, postalCode, country]
    .filter(Boolean)
    .join(', ');

  return formattedAddress || 'Address not available';
};
