import React, { forwardRef } from 'react';

interface TextareaProps extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  type: string;
  placeholder?: string;
  row?: number;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(function Input(
  { type, placeholder, className, row, ...props },
  ref
) {
  return (
    <div>
      <textarea
        placeholder={placeholder}
        className={`w-full px-2.5 py-1.5 border rounded-lg focus:outline-none focus:border-orange ${className}`}
        ref={ref}
        rows={row || 4}
        {...props}
      />
    </div>
  );
});

export default Textarea;
