import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { ColumnDef } from '@tanstack/react-table';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import formatDate from 'utils/helpers/date';
import Drawer from 'components/drawer/Drawer';
import { ArrowRightOutlined, EyeOutlined } from '@ant-design/icons';
import { EmailLogsResponse } from 'types/emailLogs';
import EmailBodyView from './components/EmailBodyView';
import { Link } from 'react-router-dom';
import paths from 'constants/path';

const getEmailLogs = async (currentPage: number) => {
  const data = await request({
    url: '/email-logs',
    method: 'GET',
    params: { page: currentPage }
  });
  return data;
};

export default function EmailLogsPage() {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: emailLogsData, isLoading } = useQuery({
    queryKey: ['email-logs', currentPage],
    queryFn: () => getEmailLogs(currentPage)
  });

  const emailLogs = useMemo(() => {
    return emailLogsData?.email_logs ?? [];
  }, [emailLogsData?.email_logs]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold mb-2'>Email Logs</h1>
        <div className='overflow-auto h-[calc(100vh_-_250px)]'>
          <DataTable isLoading={isLoading} data={emailLogs} columns={columns} />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={emailLogsData?.pagination.total_count}
        totalPage={emailLogsData?.pagination.total_pages}
        perPage={emailLogsData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

const columns: ColumnDef<EmailLogsResponse>[] = [
  {
    header: 'Created At',
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: 'Name',
    accessorKey: 'name'
  },
  {
    header: 'Status',
    accessorKey: 'status'
  },
  {
    header: 'Message Id',
    accessorKey: 'message_id'
  },
  {
    header: 'Updated At',
    accessorKey: 'updated_at',
    cell: (info) => formatDate(info.getValue<string>())
  },
  {
    header: 'Action',
    accessorKey: '',
    cell: (info) => (
      <div className='flex flex-row gap-2'>
        {info.row.original.body && (
          <Drawer
            styles={{
              header: { display: 'flex', flexDirection: 'row-reverse' }
            }}
            width='50%'
            button={<EyeOutlined className='mr-1' />}
            title='Email Body'
            closeIcon={<ArrowRightOutlined />}
          >
            <EmailBodyView info={info.row.original} setIsClose={''} />
          </Drawer>
        )}
        <Link
          to={paths.customer(info.row.original.client_record_id)}
          className='text-orange underline underline-offset-4'
        >
          Customer
        </Link>
        <Link
          to={info.row.original.ghl}
          className='text-orange underline underline-offset-4'
          target='_blank'
        >
          GHL
        </Link>
      </div>
    )
  }
];
