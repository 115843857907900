import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { ColumnDef } from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import paths from 'constants/path';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import { ArrowRightOutlined, EditOutlined } from '@ant-design/icons';
import formatDate from 'utils/helpers/date';
import Drawer from 'components/drawer/Drawer';
import { FormRequestResponse } from 'types/formRequest';
import Markdown from 'react-markdown';
import FormRequestForm from './components/FormRequestForm';

interface FormRequestFilter {
  tracking_number: string;
  email: string;
  order_date?: string[] | null;
  start_order_date?: string;
  end_order_date?: string;
}

const getFormRequests = async (
  currentPage: number,
  params?: FormRequestFilter
) => {
  const data = await request({
    url: '/form-requests',
    method: 'GET',
    params: { page: currentPage, ...params }
  });
  return data;
};

export default function FormRequest() {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: formRequestData, isLoading } = useQuery({
    queryKey: ['formRequests', currentPage],
    queryFn: () => getFormRequests(currentPage)
  });

  const formRequestMemorizedData = useMemo(() => {
    return formRequestData?.form_requests ?? [];
  }, [formRequestData?.form_requests]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='border border-light-grey bg-white rounded-xl p-2.5'>
        <h1 className='text-xl font-semibold'>Form Requests</h1>
        {/* <form onSubmit={handleSubmit(onSubmit)}>
          <div className='filters flex gap-6 items-center my-4'>
            <Controller
              name='tracking_number'
              control={control}
              render={({ field }) => (
                <Input placeholder='Tracking Number' type='text' {...field} />
              )}
            />
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <Input placeholder='Email' type='email' {...field} />
              )}
            />
            <Controller
              control={control}
              name='order_date'
              render={({ field }) => (
                <RangePicker
                  className='!px-2.5 !py-1.5'
                  format='MM/DD/YYYY'
                  {...field}
                  onChange={(_, dateStrings: [string, string]) => {
                    console.log(dateStrings);
                    field.onChange(dateStrings);
                  }}
                  value={
                    field.value
                      ? [dayjs(field.value[0]), dayjs(field.value[1])]
                      : null
                  }
                />
              )}
            />
            <Button className='w-max px-3.5 h-[38px]'>
              <SearchOutlined className='!text-white' />
            </Button>
            <button
              onClick={() =>
                reset({
                  tracking_number: '',
                  email: '',
                  order_date: null
                })
              }
              className='border border-orange rounded py-2.5 px-3'
            >
              <MdOutlineFilterAltOff className='text-orange' />
            </button>
          </div>
        </form> */}
        <div className='overflow-auto h-[calc(100vh_-_267px)]'>
          <DataTable
            isLoading={isLoading}
            data={formRequestMemorizedData}
            columns={columns}
          />
        </div>
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={formRequestData?.pagination.total_count}
        totalPage={formRequestData?.pagination.total_pages}
        perPage={formRequestData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

const columns: ColumnDef<FormRequestResponse>[] = [
  // {
  //   header: 'Client Record Id',
  //   accessorKey: 'client_record_id'
  // },
  // {
  //   header: 'Form Id',
  //   accessorKey: 'form_id'
  // },
  // {
  //   header: 'Form Request Id',
  //   accessorKey: 'form_request_id'
  // },
  {
    header: 'Prescription Notes',
    accessorKey: 'prescription_notes'
  },
  {
    header: 'Summary',
    accessorKey: 'summary',
    cell: (info) => <Markdown>{info.getValue() as string}</Markdown>
  },
  {
    header: 'Approved',
    accessorKey: 'approved'
  },
  {
    header: 'Created At',
    accessorKey: 'created_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Completed At',
    accessorKey: 'completed_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Updated At',
    accessorKey: 'updated_at',
    cell: (info) => formatDate(info.getValue() as string)
  },
  {
    header: 'Action',
    accessorKey: '',
    cell: (info) => (
      <div className='flex align-items gap-3'>
        <Drawer
          styles={{ header: { display: 'flex', flexDirection: 'row-reverse' } }}
          width='50%'
          button={
            <>
              <EditOutlined className='mr-1' />
              Edit
            </>
          }
          title='Edit Order Details'
          closeIcon={<ArrowRightOutlined />}
        >
          <FormRequestForm info={info.row.original} />
        </Drawer>
        <Link
          to={paths.customer(info.row.original.client_record_id)}
          className='text-orange underline-offset-4'
        >
          View
        </Link>
      </div>
    )
  }
];
