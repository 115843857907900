import React, { forwardRef } from 'react';
import ReactQuill, { ReactQuillProps } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface QuillEditorProps extends ReactQuillProps {
  placeholder?: string;
  className?: string;
  onChangeValue?: (value: string) => void; // Callback to return updated value
  showSaveButton?: boolean; // Prop to conditionally show the save button
}

const WysiwygEditor = forwardRef<ReactQuill, QuillEditorProps>(
  function QuillEditor(
    {
      placeholder,
      className,
      onChangeValue,
      showSaveButton = false,
      value = '',
      ...props
    },
    ref
  ) {
    // Handle save button click
    const handleSave = () => {
      if (onChangeValue) {
        onChangeValue(String(value)); // Pass the current content to the callback
      }
    };

    return (
      <div className={`w-full ${className}`}>
        <ReactQuill
          ref={ref}
          value={value}
          placeholder={placeholder || 'Type your content here...'}
          {...props}
        />
        {showSaveButton && (
          <button
            type='button'
            onClick={handleSave}
            className='border mt-2 px-2 py-0.5 bg-orange rounded hover:bg-orange-600 float-right text-white cursor-pointer'
          >
            Save
          </button>
        )}
      </div>
    );
  }
);

export default WysiwygEditor;
