import { useQuery } from '@tanstack/react-query';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import { useMemo, useState } from 'react';
import { request } from 'utils/api';
import { EditFilled } from '@ant-design/icons';
import paths from 'constants/path';
import { Link } from 'react-router-dom';

interface AiPromptsFilter {
  category_id: string;
  search_text: string;
}

const getAiPrompts = async (currentPage: number, params?: AiPromptsFilter) => {
  const data = await request({
    url: '/ai-prompts',
    method: 'GET',
    params: {
      page: currentPage,
      ...params
    }
  });
  return data;
};

export default function AiPromptsPage() {
  const [currentPage, setCurrentPage] = useState(1);

  const { data: aiPrompts, isLoading } = useQuery({
    queryKey: ['aiPrompts', currentPage],
    queryFn: () => getAiPrompts(currentPage)
  });

  const columns = [
    {
      header: 'Model',
      accessorKey: 'model'
    },
    {
      header: 'Name',
      accessorKey: 'name'
    },
    {
      header: 'Prompt',
      accessorKey: 'prompt'
    },
    {
      header: 'Actions',
      accessorKey: 'id',
      cell: (info: any) => (
        <Link to={paths.editAiPrompts(info.row.original.id)}>
          <EditFilled className='text-lg w-6 h-6 text-orange' />
        </Link>
      )
    }
  ];

  const aiPromptsMemorizedData = useMemo(() => {
    return aiPrompts?.ai_prompts ?? [];
  }, [aiPrompts?.ai_prompts]);

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      {/* <div className='flex justify-between items-center'>
        <form
          className='flex gap-6 items-center'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name='category_id'
            control={control}
            render={({ field }) => (
              <Select
                placeholder='Category'
                options={CategoryData?.map(
                  (data: { id: string; name: string }) => ({
                    value: data.id,
                    label: data.name
                  })
                )}
                allowClear
                className='w-[200px]'
                {...field}
              />
            )}
          />
          <Controller
            name='search_text'
            control={control}
            render={({ field }) => (
              <Search placeholder='Search' type='text' {...field} />
            )}
          />
          <Button className='w-max px-3.5 h-[38px]'>
            <SearchOutlined className='!text-white' />
          </Button>
          <button
            onClick={() =>
              reset({
                category_id: undefined,
                search_text: ''
              })
            }
            className='border border-orange rounded py-2 cursor-pointer px-2.5'
          >
            <MdOutlineFilterAltOff className='text-orange' />
          </button>
        </form>
        <Link to={paths.addKnowledgeBase}>
          <Button className='!w-fit px-4 py-2.5 text-xs'>
            <PlusOutlined className='mr-2' />
            Add New QnA
          </Button>
        </Link>
    
      </div> */}
      <div className='border border-light-grey rounded-xl p-8 mt-6 bg-white'>
        <h5 className='text-xl font-semibold mb-5'>Ai prompts</h5>
        <DataTable
          isLoading={isLoading}
          data={aiPromptsMemorizedData}
          columns={columns}
        />
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={aiPrompts?.pagination.total_count}
        totalPage={aiPrompts?.pagination.total_pages}
        perPage={aiPrompts?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
