import React from 'react';
import { Skeleton } from 'antd';

interface DataTableLoaderProps {
  colCount: number;
}
const ARBITRARY_NUMBER_OF_LOADING_ROWS = 6;

export default function DataTableLoader({ colCount }: DataTableLoaderProps) {
  return (
    <>
      {Array.from({ length: ARBITRARY_NUMBER_OF_LOADING_ROWS }).map(
        (_row, rowIndex) => (
          <tr key={rowIndex}>
            {Array.from({ length: colCount }).map((_col, colIndex) => (
              <td key={colIndex} className='p-4'>
                <Skeleton.Button active shape='square' size='small' block />
              </td>
            ))}
          </tr>
        )
      )}
    </>
  );
}
