import * as XLSX from 'xlsx';

type InputObject = { [key: string]: any }; // Generic object type

export const transformCSVData = (arr: InputObject[]): InputObject[] => {
  // Capitalize the first letter and replace underscores with spaces  of obj keys

  return arr.map((obj: InputObject) => {
    const transformedObj: InputObject = {};

    Object.keys(obj).forEach((key: string) => {
      const transformedKey = key
        .replace(/_/g, ' ') // Replace underscores with spaces
        .replace(/^\w/, (char: string) => char.toUpperCase());

      transformedObj[transformedKey] = obj[key];
    });

    return transformedObj;
  });
};

export const downloadXLSX = async (response: any, fileName: string) => {
  const worksheet = XLSX.utils.json_to_sheet(response);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'importedCases');

  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
  const dataToDownload = new Blob([excelBuffer], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  const excelURL = window.URL.createObjectURL(dataToDownload);
  const downloadLink = document.createElement('a');
  downloadLink.href = excelURL;
  downloadLink.setAttribute('download', `${fileName}.xlsx`);

  document.body.appendChild(downloadLink);
  downloadLink.click();

  document.body.removeChild(downloadLink);
};
