import React from 'react';

export default function ActiveIndicator({
  isActive = false
}: {
  isActive: boolean;
}) {
  return (
    <div
      className={`h-[7px] w-[7px] rounded-full ${isActive ? 'bg-green' : 'bg-brown'}`}
    />
  );
}
