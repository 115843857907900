import React, { useEffect, useRef, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
import { BsArrowRightCircleFill } from 'react-icons/bs';
import { Avatar, Spin } from 'antd';
import ReactMarkdown from 'react-markdown';
import { useMutation, useQuery } from '@tanstack/react-query';
import { request } from 'utils/api';
import { getCookie, removeCookie, setCookie } from 'utils/cookies';
import Loader from 'assets/images/loader.gif';

interface Message {
  human?: string;
  ai?: string;
}

const handleCreateSession = async () => {
  const response = await request({
    url: '/test-sessions/6662b77639380a84857ab759',
    method: 'POST'
  });
  return response;
};

const handleSendMessage = async (msg: string) => {
  const response = await request({
    url: `/chats/${getCookie('chatSession')}`,
    method: 'POST',
    data: { query: msg }
  });
  return response;
};

const loadSessionMessages = async () => {
  const response = await request({
    url: `/sessions/${getCookie('chatSession')}/messages`,
    method: 'GET'
  });
  return response;
};

export default function RetrievalTesting() {
  const [inputMessage, setInputMessage] = useState('');
  const [localMessages, setLocalMessages] = useState<Message[]>([]);
  const messageRef = useRef<any>();

  const { mutateAsync: createSession, isPending } = useMutation({
    mutationFn: () => handleCreateSession(),
    onSuccess(data) {
      if (data !== undefined) {
        setCookie('chatSession', data?.session_id);
      }
    }
  });

  const { mutateAsync: sendMessage, isPending: isMessagePending } = useMutation(
    {
      mutationFn: (msg: string) => handleSendMessage(msg),
      onSuccess(data) {
        if (data !== undefined) {
          setLocalMessages((prev) => [...prev, { ai: data?.['bot-reply'] }]);
        }
      }
    }
  );

  const handleCreateNewSession = async () => {
    removeCookie('chatSession');
    createSession();
    window.location.reload();
  };

  useEffect(() => {
    if (!getCookie('chatSession')) {
      createSession();
    }
  }, [createSession]);

  const { data: { messages } = {}, isLoading: isMessagesLoading } = useQuery<{
    messages: Message[];
  }>({
    queryKey: ['messages'],
    queryFn: () => loadSessionMessages(),
    enabled: Boolean(getCookie('chatSession'))
  });

  useEffect(() => {
    setLocalMessages(messages || []);
  }, [messages]);

  const handleSendMessageClick = () => {
    if (inputMessage.trim() !== '') {
      setLocalMessages((prev) => [...prev, { human: inputMessage }]);
      setInputMessage('');
      sendMessage(inputMessage);
    }
  };

  useEffect(() => {
    if (messageRef.current) {
      setTimeout(() => {
        messageRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 50);
    }
  }, [localMessages, isMessagePending]);

  if (isPending || isMessagesLoading) {
    return <Spin fullscreen />;
  }

  return (
    <>
      <div
        className='bg-gray-100 flex flex-col'
        style={{ height: 'calc(100vh - 102px)' }}
      >
        <div className='flex-1 overflow-y-auto p-4 mx-6'>
          <div className='mx-auto space-y-4'>
            {localMessages?.map((message, index: number) => (
              <div key={index} ref={messageRef}>
                {message.human && (
                  <div className='flex justify-end gap-2'>
                    <div className='bg-light-orange px-4 py-2 rounded-lg shadow max-w-2xl'>
                      <p className='font-medium text-gray-800'>
                        {message.human}
                      </p>
                    </div>
                    <Avatar className='bg-green' size='default'>
                      {getCookie('fullName')?.split('%20')[0][0]}
                    </Avatar>
                  </div>
                )}
                {message.ai && (
                  <div className='flex gap-2'>
                    <Avatar className='bg-blue' size='default'>
                      Ai
                    </Avatar>
                    <div className='bg-light-orange px-4 py-2 rounded-lg max-w-2xl shadow'>
                      <ReactMarkdown className='prose'>
                        {message.ai}
                      </ReactMarkdown>
                    </div>
                  </div>
                )}
              </div>
            ))}
            {isMessagePending && (
              <div className='flex'>
                <img src={Loader} alt='loader' className='w-32' />
              </div>
            )}
          </div>
        </div>
        <button
          onClick={() => handleCreateNewSession()}
          className='border border-orange w-fit flex items-center gap-1 mx-auto text-orange py-1.5 px-4 rounded-full'
        >
          <RxCross2 className='text-orange' /> Clear Session
        </button>
        <div className='p-4 flex items-center justify-between relative'>
          <input
            type='text'
            placeholder='Enter a prompt here'
            className='flex-grow px-3 py-3 bg-light-orange rounded-full max-w-5xl mx-auto focus:outline-none focus:border-orange'
            onKeyDown={(e) => {
              if (e.code === 'Enter' && inputMessage.trim() !== '') {
                handleSendMessageClick();
              }
            }}
            onChange={(e) => setInputMessage(e.target.value)}
            value={inputMessage}
          />
          {inputMessage.length > 0 && (
            <button
              disabled={isMessagePending}
              className='absolute text-2xl right-60 top-1/2 -translate-y-1/2'
              onClick={() => handleSendMessageClick()}
            >
              <BsArrowRightCircleFill className='text-orange' />
            </button>
          )}
        </div>
      </div>
    </>
  );
}
