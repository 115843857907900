import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DataTable from 'components/dataTable/DataTable';
import Pagination from 'components/pagination/Pagination';
import Search from 'components/search/Search';
import Select from 'components/select/Select';
import { useMemo, useState } from 'react';
import { request } from 'utils/api';
import Button from 'components/button/Button';
import {
  DeleteFilled,
  EditFilled,
  PlusOutlined,
  SearchOutlined
} from '@ant-design/icons';
import paths from 'constants/path';
import { Link } from 'react-router-dom';
import { MdOutlineFilterAltOff } from 'react-icons/md';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

interface KnowledgeBaseFilter {
  category_id: string;
  search_text: string;
}

const getKnowledgeBase = async (
  currentPage: number,
  params?: KnowledgeBaseFilter
) => {
  const data = await request({
    url: '/knowledge-bases',
    method: 'GET',
    params: {
      page: currentPage,
      ...params
    }
  });
  return data;
};

const deleteKnowledgeBase = async (id: string) => {
  const data = await request({
    url: `/knowledge-bases/${id}`,
    method: 'DELETE'
  });
  return data;
};

const getCategories = async () => {
  const data = await request({
    url: '/categories',
    method: 'GET'
  });
  return data;
};

export default function KnowledgeBasePage() {
  const queryClient = useQueryClient();

  const [currentPage, setCurrentPage] = useState(1);
  const [customKnowledgeBaseFilter, setCustomKnowledgeBaseFilter] = useState({
    category_id: '',
    search_text: ''
  });

  const { control, reset, handleSubmit } = useForm<KnowledgeBaseFilter>();

  const onSubmit: SubmitHandler<KnowledgeBaseFilter> = (data) => {
    setCustomKnowledgeBaseFilter(data);
  };

  const { data: KnowledgeBaseData, isLoading } = useQuery({
    queryKey: ['knowledgeBase', currentPage, customKnowledgeBaseFilter],
    queryFn: () => getKnowledgeBase(currentPage, customKnowledgeBaseFilter)
  });

  const { mutateAsync: deleteKnowledgeBaseRecord } = useMutation({
    mutationFn: (id: string) => deleteKnowledgeBase(id),
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['knowledgeBase'] });
    }
  });

  const columns = [
    {
      header: 'Category',
      accessorKey: 'category'
    },
    {
      header: 'Questions',
      accessorKey: 'question'
    },
    {
      header: 'Answers',
      accessorKey: 'answer'
    },
    {
      header: 'Actions',
      accessorKey: 'id',
      cell: (info: any) => (
        <div className='flex gap-4 items-center'>
          <Link to={paths.editKnowledgeBase(info.row.original.id)}>
            <EditFilled className='text-lg w-6 h-6 text-orange' />
          </Link>
          <DeleteFilled
            onClick={() => deleteKnowledgeBaseRecord(info.row.original.id)}
            className='text-lg w-6 h-6 text-red cursor-pointer'
          />
        </div>
      )
    }
  ];

  const KnowledgeBaseMemorizedData = useMemo(() => {
    return KnowledgeBaseData?.knowledge_bases ?? [];
  }, [KnowledgeBaseData?.knowledge_bases]);

  const { data: CategoryData } = useQuery({
    queryKey: ['categoryData'],
    queryFn: () => getCategories()
  });

  return (
    <div className='p-5 bg-[#FAFBFC]'>
      <div className='flex justify-between items-center'>
        <form
          className='flex gap-6 items-center'
          onSubmit={handleSubmit(onSubmit)}
        >
          <Controller
            name='category_id'
            control={control}
            render={({ field }) => (
              <Select
                placeholder='Category'
                options={CategoryData?.map(
                  (data: { id: string; name: string }) => ({
                    value: data.id,
                    label: data.name
                  })
                )}
                allowClear
                className='w-[200px]'
                {...field}
              />
            )}
          />
          <Controller
            name='search_text'
            control={control}
            render={({ field }) => (
              <Search placeholder='Search' type='text' {...field} />
            )}
          />
          <Button className='w-max px-3.5 h-[38px]'>
            <SearchOutlined className='!text-white' />
          </Button>
          <button
            onClick={() =>
              reset({
                category_id: undefined,
                search_text: ''
              })
            }
            className='border border-orange rounded py-2 cursor-pointer px-2.5'
          >
            <MdOutlineFilterAltOff className='text-orange' />
          </button>
        </form>
        <Link to={paths.addKnowledgeBase}>
          <Button className='!w-fit px-4 py-2.5 text-xs'>
            <PlusOutlined className='mr-2' />
            Add New QnA
          </Button>
        </Link>
      </div>
      <div className='border border-light-grey rounded-xl p-8 mt-6 bg-white'>
        <h5 className='text-xl font-semibold mb-5'>General</h5>
        <DataTable
          isLoading={isLoading}
          data={KnowledgeBaseMemorizedData}
          columns={columns}
          isVerticalBorderEnabled={true}
        />
      </div>
      <Pagination
        isLoading={isLoading}
        totalCount={KnowledgeBaseData?.pagination.total_count}
        totalPage={KnowledgeBaseData?.pagination.total_pages}
        perPage={KnowledgeBaseData?.pagination.per_page}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}
