import {
  DatePicker as DatePickerComponent,
  DatePickerProps as AntDatePickerProps
} from 'antd';

interface DatePickerProps extends AntDatePickerProps {
  className?: string;
}

export default function DatePicker({ className, ...props }: DatePickerProps) {
  return (
    <DatePickerComponent
      format='MM-DD-YYYY'
      className={`w-full mt-2 py-3 px-5 ${className}`}
      {...props}
    />
  );
}
